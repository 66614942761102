import React, {useEffect, useMemo, useState} from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import {useConfirm} from 'src/hooks/use-confirm'
import {
  cleanParamsAPI,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
  convertDateStringDayMonthYear,
} from 'src/utils'
import {replaceValueAll} from 'src/utils/string'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import {FILTER_SELECT_SORTBY_EXAM} from 'src/constants/classes'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import {Select} from 'antd'
import {BUTTON_TEXT, DESCRIPTION_POPUPCONFIRM} from 'src/constants/lang'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import TableExamACCA from './tableExamsACCA'
import {ClassesApi} from 'src/apis/classes'
import TableExamCFA from './tableExamsCFA'
import TableExamCMA from './tableExamCMA'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'

const {Option} = Select

interface IProps {
  open: boolean
  setOpen: any
  courseId?: any
  course_category_id?: string
  course_category_type?: string
  checkedList: any
  setCheckedList: React.Dispatch<React.SetStateAction<any>>
}
const fieldNames = [
  'season',
  'sort_type',
  'fromDate',
  'toDate',
  'text',
  'openRegistry',
  'earlyRegistry',
  'confirmRegistry',
  'firstRegistry',
  'finalRegistry',
  'lastRegistry',
  'dateField',
]
const initialValues: any = {
  season: '',
  sort_type: '',
  fromDate: '',
  toDate: '',
  text: '',
  openRegistry: '',
  earlyRegistry: '',
  confirmRegistry: '',
  firstRegistry: '',
  finalRegistry: '',
  lastRegistry: '',
  dateField: '',
}
const AddExam = ({
  open,
  setOpen,
  courseId,
  course_category_id,
  course_category_type,
  checkedList,
  setCheckedList,
}: IProps) => {
  const [searchParams, setSearchParams] = useState<any>(initialValues)

  const {confirm, contextHolder} = useConfirm()
  const [loading, setLoading] = useState(false)
  const getParams = (
    season: string,
    sort_type: string,
    fromDate?: any,
    toDate?: any,
    text?: string,
    openRegistry?: string,
    earlyRegistry?: string,
    confirmRegistry?: string,
    firstRegistry?: string,
    finalRegistry?: string,
    lastRegistry?: string,
    dateField?: string
  ) => ({
    season,
    sort_type,
    fromDate: fromDate,
    toDate: toDate,
    text: text,
    openRegistry: openRegistry,
    earlyRegistry: earlyRegistry,
    confirmRegistry: confirmRegistry,
    firstRegistry: firstRegistry,
    finalRegistry: finalRegistry,
    lastRegistry: lastRegistry,
    dateField,
  })
  const queryParams = {
    season: '',
    sort_type: '',
    fromDate: '',
    toDate: '',
    text: '',
    openRegistry: '',
    earlyRegistry: '',
    confirmRegistry: '',
    firstRegistry: '',
    finalRegistry: '',
    lastRegistry: '',
    page_index: 1,
    page_size: 10,
  }
  const [examList, setExamList] = useState<any>()
  const [selectExam, setSelectExam] = useState<any>([])

  useEffect(() => {
    setSelectExam(checkedList)
  }, [checkedList, open])

  const handleSubmit = () => {
    setOpen({status: false, type: ''})
    setCheckedList(selectExam)
    reset()
  }

  const toggleCheck = (data: any) => {
    setSelectExam([data])
  }

  const validationSchema = z.object({
    season: z.any().optional(),
    sort_type: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    text: z.string().optional(),
    openRegistry: z.any().optional(),
    earlyRegistry: z.any().optional(),
    confirmRegistry: z.any().optional(),
    firstRegistry: z.any().optional(),
    finalRegistry: z.any().optional(),
    lastRegistry: z.any().optional(),
  })

  const {control, getValues, reset, setValue} = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const fetchExamList = async (currentPage: number, pageSize: number) => {
    setLoading(true)
    try {
      const dateInfoFromDate = searchParams?.fromDate
      const dateInfoToDate = searchParams?.toDate

      const showSearchParams =
        searchParams.season ||
        searchParams.sort_type ||
        searchParams.fromDate ||
        searchParams.toDate ||
        searchParams.text ||
        searchParams.openRegistry ||
        searchParams.earlyRegistry ||
        searchParams.confirmRegistry ||
        searchParams.firstRegistry ||
        searchParams.finalRegistry ||
        searchParams.lastRegistry

      // TODO: check xem có tồn tại params ở trên router không?
      const cleanedParams = cleanParamsAPI(
        getParams(
          searchParams?.season,
          searchParams?.sort_type,
          searchParams?.fromDate
            ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
            : '',
          searchParams.toDate
            ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
            : '',
          searchParams?.text,
          searchParams?.openRegistry,
          searchParams?.earlyRegistry,
          searchParams?.confirmRegistry,
          searchParams?.firstRegistry,
          searchParams?.finalRegistry,
          searchParams?.lastRegistry,
          showSearchParams ? 'updated_at' : 'created_at'
        )
      )
      getListExam(currentPage, pageSize, cleanedParams)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  const getListExam = async (currentPage: number, pageSize: number, params?: any) => {
    const res = await ClassesApi.getListExam({
      id: course_category_id,
      page_index: currentPage || 1,
      page_size: pageSize || 10,
      params: params,
    })
    setExamList(res?.data || [])
  }

  const convertDateRange = (value: any) => {
    const convertRange =
      value !== '' && value?.fromDate !== null && value?.toDate !== null
        ? `${convertDateStringDayMonthYear(value?.fromDate)};${convertDateStringDayMonthYear(
            value?.toDate
          )}`
        : ''
    return convertRange
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))

    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('season') ?? '',
        replaceValueAll(getValues('sort_type')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        getValues('text')?.trimStart()?.trimEnd() ?? '',
        convertDateRange(getValues('openRegistry')) ?? '',
        convertDateRange(getValues('earlyRegistry')) ?? '',
        convertDateRange(getValues('confirmRegistry')) ?? '',
        convertDateRange(getValues('firstRegistry')) ?? '',
        convertDateRange(getValues('finalRegistry')) ?? '',
        convertDateRange(getValues('lastRegistry')) ?? '',
        'updated_at'
      )
    )
    setSearchParams(cleanedParams)
    getListExam(1, 10, cleanedParams)
  }

  const handleResetFilter = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setSearchParams(initialValues)
    getListExam(1, 10, {})
  }

  useEffect(() => {
    if (open) {
      getListExam(1, 10, {})
    }
  }, [open])

  useEffect(() => {
    if (courseId !== undefined) {
      handleResetFilter()
    }
  }, [courseId])

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchExamList(page_index, page_size)
  }

  const type = useMemo(() => {
    return course_category_type
  }, [course_category_type])

  const handleClose = () => {
    let body = [DESCRIPTION_POPUPCONFIRM]
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: () => {
        setOpen({status: false, type: ''})
        reset()
      },
    })
  }

  return (
    <SappModal
      title=''
      hideHeader
      open={open}
      handleClose={handleClose}
      dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
      classNameBody={'sapp-m-h-unset'}
      showFooter={false}
    >
      {contextHolder}
      <div className='d-flex justify-content-between px-0 align-items-center'>
        <div className='sapp-title-modal-create-class'>{`Add ${
          type?.toUpperCase() || 'ACCA'
        } Exam`}</div>
        <div className='d-flex align-items-center'>
          {selectExam.length > 0 && (
            <div className='sapp-selected-count fs-6 me-5'>{`${selectExam.length} Selected`}</div>
          )}
          <SAPPDialogButtonsCancelSubmit
            type='button'
            cancelClick={handleClose}
            cancelButtonCaption='Cancel'
            okButtonCaption='Add'
            okOnClick={handleSubmit}
            className='justify-content-between d-flex flex-row-reverse'
            classNameCancel='fw-bold me-0'
            classNameSubmit='fw-bold me-5'
            isListScreen
            // loading={loading}
          />
        </div>
      </div>
      <div className='px-0'>
        <div className='card-header border-0 pt-6 px-0'>
          <div className=''>
            <div className='row'>
              {type === 'ACCA' && (
                <div className='col-xl-3 col-sm-4'>
                  <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                    <HookFormTextField
                      control={control}
                      name='text'
                      placeholder='Search note'
                      defaultValue={queryParams?.text}
                      isListScreen
                    />
                  </div>
                </div>
              )}
              {(type === 'ACCA' || type === 'CFA') && (
                <div className='col-xl-3 col-sm-4 mt-2 '>
                  <HookFormDateTime
                    control={control}
                    name='season'
                    placeholder='Kỳ thi'
                    defaultValue={queryParams?.season}
                  />
                </div>
              )}
              {type === 'CFA' && (
                <>
                  <div className='col-xl-3 col-sm-4 mt-2 '>
                    <RangeDateTimePicker
                      control={control}
                      name='openRegistry'
                      allowClear={true}
                      placeholder={['Mở đăng ký', 'To']}
                      defaultValue={queryParams?.openRegistry}
                    />
                  </div>
                  <div className='col-xl-3 col-sm-4 mt-2 '>
                    <RangeDateTimePicker
                      control={control}
                      name='earlyRegistry'
                      allowClear={true}
                      placeholder={['Đăng ký sớm', 'To']}
                      defaultValue={queryParams?.earlyRegistry}
                    />
                  </div>
                  <div className='col-xl-3 col-sm-4 mt-2 '>
                    <RangeDateTimePicker
                      control={control}
                      name='confirmRegistry'
                      allowClear={true}
                      placeholder={['Đăng ký chuẩn', 'To']}
                      defaultValue={queryParams?.confirmRegistry}
                    />
                  </div>
                </>
              )}
              {type === 'CMA' && (
                <>
                  <div className='col-xl-3 col-sm-4'>
                    <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                      <HookFormTextField
                        control={control}
                        name='text'
                        placeholder='Search Kỳ thi'
                        defaultValue={queryParams?.text}
                        onSubmit={onSubmit}
                      />
                    </div>
                  </div>
                  <div className='col-xl-3 col-sm-4 mt-2 '>
                    <HookFormSelectAntd
                      name='season'
                      placeholder='Năm'
                      control={control}
                      size='large'
                      defaultValue={queryParams?.season ?? ''}
                      className='sapp-fs-select fs-6'
                    >
                      <Option key='All' value=' '>
                        All
                      </Option>
                      {Array.from({length: 2050 - 2020 + 1}, (_, index) => {
                        const year = 2020 + index
                        return (
                          <Option key={year} value={year}>
                            {year}
                          </Option>
                        )
                      })}
                    </HookFormSelectAntd>
                  </div>
                  <div className='col-xl-3 col-sm-4 mt-2 '>
                    <RangeDateTimePicker
                      control={control}
                      name='firstRegistry'
                      allowClear={true}
                      placeholder={['Ngày thi đầu', 'To']}
                      defaultValue={queryParams?.firstRegistry}
                    />
                  </div>
                  <div className='col-xl-3 col-sm-4 mt-2 '>
                    <RangeDateTimePicker
                      control={control}
                      name='finalRegistry'
                      allowClear={true}
                      placeholder={['Ngày thi cuối', 'To']}
                      defaultValue={queryParams?.finalRegistry}
                    />
                  </div>
                  <div className='col-xl-3 col-sm-4 mt-2 mt-xl-5 '>
                    <RangeDateTimePicker
                      control={control}
                      name='lastRegistry'
                      allowClear={true}
                      placeholder={['Ngày đăng ký cuối', 'To']}
                      className='sapp-h-datimepicker sapp-width-picker-range'
                    />
                  </div>
                </>
              )}
              <div className={`col-xl-3 col-sm-4 mt-2 ${type !== 'ACCA' ? 'mt-xl-5' : ''}`}>
                <HookFormSelectAntd
                  name='sort_type'
                  placeholder='Sort by'
                  control={control}
                  size='large'
                  defaultValue={queryParams?.sort_type ?? ''}
                  className='sapp-fs-select fs-6'
                >
                  {FILTER_SELECT_SORTBY_EXAM.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              {/*<div className='col-xl-3 col-sm-4 mt-2 '>
                <HookFormDateTime
                  control={control}
                  name='fromDate'
                  placeholder='From date'
                  defaultValue={queryParams?.fromDate}
                  
                />
              </div>
              <div className='col-xl-3 col-sm-4 mt-2 '>
                <HookFormDateTime
                  control={control}
                  name='toDate'
                  placeholder='To date'
                  defaultValue={queryParams?.toDate}
                  
                />
              </div>*/}
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6 px-0'>
          <div className='container m-0'>
            <div className='row'>
              <div className='col-sm-4 col-lg-8 col-xl-4 px-0'>
                <SAPPFIlterButton
                  titleReset='Reset'
                  okClick={onSubmit}
                  resetClick={handleResetFilter}
                  titleSubmit={BUTTON_TEXT.SEARCH}
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
        {type === 'CFA' ? (
          <TableExamCFA
            checkedList={selectExam}
            examList={examList}
            handlePaginationChange={handlePaginationChange}
            loading={loading}
            toggleCheck={toggleCheck}
          />
        ) : type === 'ACCA' ? (
          <TableExamACCA
            checkedList={selectExam}
            examList={examList}
            handlePaginationChange={handlePaginationChange}
            loading={loading}
            toggleCheck={toggleCheck}
          />
        ) : (
          <TableExamCMA
            checkedList={selectExam}
            examList={examList}
            handlePaginationChange={handlePaginationChange}
            loading={loading}
            toggleCheck={toggleCheck}
          />
        )}
      </div>
    </SappModal>
  )
}
export default AddExam
