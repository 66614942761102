import { Dispatch, SetStateAction, useState } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import { useConfirm } from 'src/hooks/use-confirm'
import ButtonIcon from '../button/ButtonIcon'
import ButtonIconOnly from '../button/ButtonIconOnly'
import ModalUploadFile from './ModalUploadFile/ModalUploadFile'
import { RESOURCE_LOCATION, UPLOAD_TYPE } from './ModalUploadFile/UploadFileInterface'
import './UploadMulti.scss'
import { Skeleton, UploadFile } from 'antd'
import ModalPreviewFile from 'src/components/question-bank/preview-question/ModalPreviewFile'
import React from 'react'
import { ArrayFieldForm, Role } from 'src/type'
import {
  CODE_ADMIN,
  TITLE_COURSE_GR,
  TITLE_NOTIFICATIONS_GR,
  TITLE_QUESTIONS_GR,
  TITLE_RESOURCES_GR,
  TITLE_TOPIC_GR,
} from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'

type Props = {
  label?: string
  required?: boolean
  setFileList: any
  fileList?: any[]
  error?: any
  acceptFiles?: { type: string; size: number }[]
  guideline?: string[]
  resourceLocation: RESOURCE_LOCATION
  fileType?: keyof typeof UPLOAD_TYPE
  buttonTitle?: string
  onlyTab?: 'UPLOAD_FILE' | 'RESOURCES'
  title?: string
  customValidate?: (file: UploadFile<any>, fileList: UploadFile<any>[], index: number) => boolean
  parentId?: string
  maxCount?: number
  handleRemoveAdditional?: (id: string) => Promise<any>
  disabled?: boolean
  loading?: boolean
  formAddQuestion?: ArrayFieldForm | undefined
  object_id?: string | null | undefined
  setFileEditor?: Dispatch<SetStateAction<any>>
  isMultiple?: boolean
  isScreenList?: boolean
}

const UploadMulti = ({
  fileList,
  setFileList,
  guideline,
  error,
  resourceLocation,
  fileType = 'DOCUMENT',
  buttonTitle = 'Upload File',
  onlyTab,
  title,
  customValidate,
  parentId,
  maxCount,
  handleRemoveAdditional,
  disabled = false,
  loading = false,
  // formAddQuestion,
  isMultiple = true,
  object_id,
  // setFileEditor,
  isScreenList = true,
}: Props) => {
  const { confirm, contextHolder } = useConfirm()
  const { profileMe } = useUserContext()
  const [modalUpload, setModalUpload] = useState<boolean>(false)
  const [openPreviewFile, setOpenPreviewFile] = useState({ status: false, url: '' })
  const handleRemove = (id: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        try {
          handleRemoveAdditional && (await handleRemoveAdditional(id))
          const deleted = fileList?.filter((f: any) => f.id !== id)
          setFileList(deleted)
        } catch (error) {}
      },
    })
  }
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_NOTIFICATIONS_GR.EDIT_NOTIFICATION)
  const allowRenderCreateQuestion = hasPermission(
    profileMe?.roles,
    TITLE_QUESTIONS_GR.CREATE_QUESTION
  )
  const allowRenderCreateActivity =
    hasPermission(profileMe?.roles, TITLE_COURSE_GR.CREATE_COURSE) ||
    hasPermission(profileMe?.roles, TITLE_COURSE_GR.EDIT_COURSE)
  const allowRenderCreateEditTopic =
    hasPermission(profileMe?.roles, TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC) ||
    hasPermission(profileMe?.roles, TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC)
  const allowRenderCreateResources = hasPermission(
    profileMe?.roles,
    TITLE_RESOURCES_GR.CREATE_RESOURCES
  )

  const allowUpload =
    allowRenderEdit ||
    allowRenderCreateQuestion ||
    allowRenderCreateActivity ||
    allowRenderCreateEditTopic ||
    allowRenderCreateResources

  const handleSetFiles = (files: any, type: string) => {
    if (type === 'resources') {
      setFileList([...(files || [])])
    } else {
      setFileList([...(fileList || []), ...(files || [])])
    }

    // setFileEditor && setFileEditor((prevData: any) => [...prevData, ...files])
  }

  return (
    <div>
      {contextHolder}
      <div className='h-lg-50 d-flex align-items-center flex-wrap row-gap-3'>
        <ButtonIcon
          disabled={disabled}
          title={buttonTitle}
          className='w-fit-content sapp-height-min--content d-flex align-items-center text-start me-4 fw-bold'
          customButton='btn-sm btn-light'
          onClick={(e) => {
            if (allowUpload) {
              setModalUpload(true)
              e?.target?.blur()
            }
          }}
          isListScreen={isScreenList}
        >
          <KTIcon iconName='arrow-up' iconType='outline' className='fs-4 text-inverse-light' />
        </ButtonIcon>
        <ModalUploadFile
          open={modalUpload}
          setOpen={setModalUpload}
          fileType={fileType}
          setSelectedFile={handleSetFiles}
          isMultiple={isMultiple}
          resourceLocation={resourceLocation}
          fileChecked={fileList}
          onlyTab={onlyTab}
          title={title}
          customValidate={customValidate}
          parentId={parentId}
          maxCount={maxCount}
          object_id={object_id}
        ></ModalUploadFile>
        <div className='sapp-flex-1 min-w-150px'>
          <GuidelineField guideline={guideline} classString={'mt-0'} />
        </div>
      </div>
      {loading ? (
        <>
          {[0, 1, 2].map((e) => (
            <React.Fragment key={e}>
              <Skeleton.Button active block size='large' className='sapp-h-45px mt-3' />
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          {fileList?.map((file: any) => (
            <div key={file.id || file.resource_id}>
              <div className='d-flex flex-stack py-3'>
                <div
                  className='text-gray-700 fw-semibold fs-6 me-2 cursor-pointer'
                  onClick={() => {
                    if (allowUpload) {
                      setOpenPreviewFile({ status: true, url: file.url })
                    }
                  }}
                >
                  {file.name}
                </div>
                <div className='d-flex align-items-center'>
                  <ButtonIconOnly
                    iconName={'trash'}
                    activeColor='danger'
                    onClick={() => {
                      if (!disabled) {
                        handleRemove(file.id)
                      }
                    }}
                    disabled={!allowUpload || disabled}
                  />
                </div>
              </div>
              {file?.id && file?.id !== setFileList?.[setFileList?.length - 1]?.id && (
                <div className='separator separator-dashed'></div>
              )}
            </div>
          ))}
        </>
      )}
      <ErrorMessage>{error?.message}</ErrorMessage>
      <ModalPreviewFile
        open={openPreviewFile.status}
        setOpen={setOpenPreviewFile}
        url={openPreviewFile.url}
      />
    </div>
  )
}

export default UploadMulti
