import React, {Dispatch, SetStateAction} from 'react'
import {Link, useLocation} from 'react-router-dom'
import ActionCell from '../base/action/ActionCell'
import {useUserContext} from 'src/context/UserProvider'
import {Role} from 'src/type'
import {CODE_ADMIN, TITLE_GR, TITLE_STAFF_GR} from 'src/constants/permission'

interface IProps {
  setOpenReset: Dispatch<SetStateAction<boolean>>
  setOpenBlocked: Dispatch<SetStateAction<boolean>>
  status: 'BLOCKED' | 'MERGED'
  linkProfile: string
  setOpenEditEmail: Dispatch<SetStateAction<boolean>>
  linkEdit: string
}

const UserAction = ({
  setOpenBlocked,
  setOpenReset,
  status,
  linkProfile,
  setOpenEditEmail,
  linkEdit,
}: IProps) => {
  const {profileMe} = useUserContext()
  const location = useLocation()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)
  const allowRenderEditStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderResetPassword = hasPermission(
    profileMe?.roles,
    TITLE_GR.PUT_RESET_PASSWORD_USER_GR
  )
  const allowRenderResetPasswordStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.RESET_PASSWORD_STAFF
  )
  const allowRenderBlock = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)
  const allowRenderBlockStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderEditEmail = hasPermission(profileMe?.roles, TITLE_GR.PUT_CHANGE_EMAIL_USER_GR)
  const allowRenderEditEmailStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.CHANGE_EMAIL_STAFF
  )
  const allowRenderViewProfile = hasPermission(profileMe?.roles, TITLE_GR.VIEW_USER_GR)
  const allowRenderViewProfileStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.GET_STAFF)

  return (
    <>
      <ActionCell customWidth='w-150px'>
        {/* begin::Menu */}
        {/* begin::Menu item */}
        {status !== 'MERGED' && (
          <>
            {((allowRenderEdit &&
              (location.pathname === '/students' || location.pathname === '/teachers')) ||
              (allowRenderEditStaff && location.pathname === '/staffs') ||
              (allowRenderEditStaff && allowRenderEdit)) && (
              <div className='menu-item px-3'>
                <Link to={linkEdit} className='menu-link px-3'>
                  Edit
                </Link>
              </div>
            )}
            {/* end::Menu item */}
            {((allowRenderResetPassword &&
              (location.pathname === '/students' || location.pathname === '/teachers')) ||
              (allowRenderResetPasswordStaff && location.pathname === '/staffs') ||
              (allowRenderResetPassword && allowRenderResetPasswordStaff)) && (
              <div className='menu-item px-3' onClick={() => setOpenReset(true)}>
                <div className='menu-link px-3'>Reset Password</div>
              </div>
            )}
            {((status !== 'BLOCKED' &&
              allowRenderBlock &&
              (location.pathname === '/students' || location.pathname === '/teachers')) ||
              (allowRenderBlockStaff && location.pathname === '/staffs') ||
              (allowRenderBlock && allowRenderBlockStaff)) && (
              <div className='menu-item px-3'>
                <div className='menu-link px-3' onClick={() => setOpenBlocked(true)}>
                  Block
                </div>
              </div>
            )}
            {((allowRenderEditEmail &&
              (location.pathname === '/students' || location.pathname === '/teachers')) ||
              (allowRenderEditEmailStaff && location.pathname === '/staffs') ||
              (allowRenderEditEmail && allowRenderEditEmailStaff)) && (
              <div className='menu-item px-3' onClick={() => setOpenEditEmail(true)}>
                <div className='menu-link px-3'>Edit Email</div>
              </div>
            )}
          </>
        )}
        {((allowRenderViewProfile &&
          (location.pathname === '/students' || location.pathname === '/teachers')) ||
          (allowRenderViewProfileStaff && location.pathname === '/staffs') ||
          (allowRenderViewProfile && allowRenderViewProfileStaff)) && (
          <Link to={linkProfile} className='menu-item px-3'>
            <div className='menu-link px-3'>View Profile</div>
          </Link>
        )}

        {/* end::Menu item */}
      </ActionCell>
    </>
  )
}

export default UserAction
