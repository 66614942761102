import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import './addVideo.scss'
import AddVideoArea from './addVideoArea'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ModalUploadFile from 'src/components/base/upload-file/ModalUploadFile/ModalUploadFile'
import VideoSetting from './videoSetting'
import { ResourcesAPI } from 'src/apis/resources'
import { TYPE_DOCUMENT } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { Spin } from 'antd'
import toast from 'react-hot-toast'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import { useParams } from 'react-router-dom'
const defaultValue = {
  multiple_video: 'false',
}
interface IProps {
  handleClose: any
  onSubmit: any
  data?: any //oldData for edit
  loading?: boolean
}
const AddTabVideo = ({ handleClose, onSubmit, data, loading }: IProps) => {
  const params = useParams()
  const { control, setValue, watch } = useForm({
    defaultValues: defaultValue,
  })
  const watchType = watch('multiple_video')
  const [open, setOpen] = useState<{ status: boolean; index?: number }>({
    status: false,
  })
  const [selectedFile, setSelectedFile] = useState<any[]>([{}, {}, {}])
  const [singleSelectedFile, setSingleSeletedFile] = useState<any>()
  const [openSetting, setOpenSetting] = useState<{
    status: boolean
    tabActive?: number
    resource_id?: string
    parent_id?: string
  }>({ status: false })
  const [loadingDetail, setLoadingDetail] = useState(false)
  const { confirm, contextHolder } = useConfirm()

  useEffect(() => {
    async function getDetailData() {
      try {
        if (data) {
          if (data.videos.length > 1) {
            setValue('multiple_video', 'true')
            const arrFile: any = []
            for (let e of data.videos) {
              try {
                const res = await ResourcesAPI.getUrl(e.file.resource_id)
                arrFile.push(res.data)
              } catch (err) {
                console.error(err)
              }
            }
            setSelectedFile((prev: any) => {
              const arr = [...prev]
              arr[0] = arrFile[0] || {}
              arr[1] = arrFile[1] || {}
              arr[2] = arrFile[2] || {}
              return arr
            })
          } else if (data.videos.length === 1) {
            setValue('multiple_video', 'false')
            const res = await ResourcesAPI.getUrl(data.videos[0].file.resource_id)
            setSingleSeletedFile(res.data)
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        setLoadingDetail(false)
      }
    }
    if (data) {
      setLoadingDetail(true)
      getDetailData()
    }
  }, [data])
  //neu chua chon file nao se ko cho submit
  const handleCheckSelectedFile = useMemo(() => {
    if (watchType !== 'false') {
      for (let e of selectedFile) {
        if (e.quiz_id) {
          return true
        }
      }
    } else if (watchType === 'false') {
      if (singleSelectedFile?.quiz_id) {
        return true
      }
    }
    return false
  }, [selectedFile, singleSelectedFile, watchType])
  // const checkNumOfVideo = useMemo(() => {
  //   if (selectedFile.length >= 3) {
  //     return true
  //   }
  //   return false
  // }, [selectedFile])
  const handleSelectFile = async (e: any, type: string) => {
    const res = await ResourcesAPI.getUrl(e[0].id)
    if (watchType !== 'false') {
      setSelectedFile((prev: any) => {
        // if (watchType !== 'false') {
        const arr = [...prev]
        if (type === 'upload') {
          arr[open.index || 0] = { ...res.data, upload: true }
        } else arr[open.index || 0] = res.data
        return arr
        // } else {
        //   return [res.data]
        // }
      })
    } else if (watchType === 'false') {
      setSingleSeletedFile((prev: any) => {
        if (type === 'upload') {
          return { ...res.data, upload: true }
        } else {
          return res.data
        }
      })
      // const res = await ResourcesAPI.getUrl(e[0].id)
    }
    if (type === 'upload') {
      setOpenSetting({
        status: true,
        resource_id: e[0].id,
        parent_id: res.data.parent_id,
      })
    }
    setOpen({ status: false })
    // }
  }
  const handleDeleteVideo = (index: number) => {
    let body = ['Bạn có chắc chắn muốn xoá video này không?']
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: async () => {
        if (watchType !== 'false') {
          const oldArr = [...selectedFile]
          oldArr[index] = {}
          setSelectedFile(oldArr)
        } else {
          setSingleSeletedFile({})
        }
      },
    })
  }
  return (
    <div className='pb-6'>
      {contextHolder}
      <div className='border-solid rounded border border-gray-300 sapp-py-24px'>
        <div className='d-flex justify-content-between  align-items-start sapp-px-24px border-bottom'>
          <div className='d-flex'>
            <div
              className={`bg-blue-500 text-gray-800 pb-7 focus:outline-none sapp-fs-14 fw-semibold sapp-lh-16px`}
              style={{
                borderBottom: '1px solid #FFB800',
                borderTop: '0',
                borderLeft: '0',
                borderRight: '0',
                background: 'none',
              }}
            >
              New Video Document
            </div>
          </div>
          {/* <div className='d-flex gap-2 justify-content-center align-items-center'></div> */}
        </div>
        <Spin spinning={loadingDetail} size='large'>
          <div className='sapp-px-24px sapp-pt-24px'>
            <div className='sapp-mb-24px d-flex justify-content-between align-items-center'>
              <HookFormRadioGroup
                labelClass='fw-semibold fs-6'
                direction='horizontal'
                separator={false}
                name='multiple_video'
                control={control}
                justify='start'
                gap={20}
                options={[
                  {
                    label: 'Single Video',
                    value: 'false',
                  },
                  {
                    label: 'Multiple Videos',
                    value: 'true',
                  },
                ]}
              />
            </div>
            {/* {multipleUpload === 'true' ? ( */}
            {watchType !== 'false' && (
              <div className='alert alert-dismissible bg-light-alert-primary border border-alert-primary border-dashed d-flex flex-column flex-sm-row p-5 mb-10 align-items-center'>
                <i className='ki-duotone ki-information-5 fs-2hx text-success me-4 mb-5 mb-sm-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>

                <div className='d-flex flex-column pe-0 pe-sm-10'>
                  <h5 className='mb-1 sapp-title-alert'>Chú ý về Multiple Videos</h5>

                  <span className='sapp-content-alert'>
                    Chỉ có thể cài đặt tối đa 3 videos trong một document
                  </span>
                </div>
              </div>
            )}
            {watchType !== 'false' ? (
              selectedFile?.map((e, i) => (
                <AddVideoArea
                  key={i}
                  onClick={() => setOpen({ status: true, index: i })}
                  data={e}
                  handleDelete={() => handleDeleteVideo(i)}
                  handleEdit={(tabActive: number) =>
                    setOpenSetting({
                      status: true,
                      resource_id: e.id,
                      parent_id: e.parent_id,
                      tabActive,
                    })
                  }
                  index={i}
                />
              ))
            ) : (
              <AddVideoArea
                onClick={() => setOpen({ status: true })}
                data={singleSelectedFile}
                handleDelete={() => handleDeleteVideo(0)}
                handleEdit={(tabActive: number) =>
                  setOpenSetting({
                    status: true,
                    resource_id: singleSelectedFile.id,
                    parent_id: singleSelectedFile.parent_id,
                    tabActive,
                  })
                }
                index={0}
              />
            )}
          </div>

          <div
            className='d-flex justify-content-end sapp-footer-create-doc sapp-px-24px py-0'
            style={{ border: 'none' }}
          >
            <ButtonSecondary
              title='Cancel'
              className='btn me-5'
              onClick={handleClose}
              disabled={loading}
            />
            <ButtonPrimary
              onClick={() => {
                if (handleCheckSelectedFile) {
                  if (watchType !== 'false') {
                    onSubmit(selectedFile, TYPE_DOCUMENT.VIDEO)
                  } else {
                    onSubmit([singleSelectedFile], TYPE_DOCUMENT.VIDEO)
                  }
                } else {
                  toast.error('Please add at least one video before submit!')
                }
              }}
              // className='btn btn-active-primary'
              title='Save Document'
              loading={loading}
              // disabled={!handleCheckSelectedFile}
            />
          </div>
          <ModalUploadFile
            open={open.status}
            setOpen={setOpen}
            fileType='VIDEO'
            setSelectedFile={handleSelectFile}
            resourceLocation={RESOURCE_LOCATION.COURSE}
          />
        </Spin>
      </div>
      <ModalUploadFile
        open={open.status}
        setOpen={setOpen}
        fileType='VIDEO'
        setSelectedFile={handleSelectFile}
        resourceLocation={RESOURCE_LOCATION.COURSE}
        submitButtonTitle={'Save'}
        object_id={params?.id}
      />
      <VideoSetting
        open={openSetting.status}
        setOpen={setOpenSetting}
        tabActive={openSetting.tabActive !== undefined ? openSetting.tabActive : 1}
        // url={openSetting.url}
        // name={openSetting.name}
        // quiz_id={openSetting.quiz_id}
        // time_line={openSetting.time_line}
        resource_id={openSetting.resource_id}
      />
    </div>
  )
}
export default AddTabVideo
