import {fetcher} from 'src/services/request'
import {IResponse} from 'src/type'

export class CertificatesApi {
  static get({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`certificate?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getConfigText(): Promise<IResponse<any>> {
    return fetcher('/certificate/config-text')
  }

  static createCertificates({data}: {data: any}): Promise<IResponse<any>> {
    return fetcher('/certificate', {method: 'POST', data: data})
  }

  static detail(id: string | undefined): Promise<IResponse<any>> {
    return fetcher(`/certificate/${id}`)
  }

  static delete(id: string | undefined): Promise<IResponse<any>> {
    return fetcher(`/certificate/${id}`, {
      method: 'DELETE',
    })
  }

  static editCertificates(data: any, id: string): Promise<IResponse<any>> {
    return fetcher(`certificate/${id}`, {
      method: 'PUT',
      data: data,
    })
  }
}
