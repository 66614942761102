import {zodResolver} from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import toast from 'react-hot-toast'
import {ClassesApi} from 'src/apis/classes'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {VALIDATE_NUMBER, VALIDATION_MIN_EQUAL} from 'src/constants'
import {OPTIONS_OF_DURATION} from 'src/constants/classes'
import {z} from 'zod'

interface IProps {
  open: boolean
  setOpen: any
  data: any
  fetchClasses: any
}

const UpdateDuration = ({data, open, setOpen, fetchClasses}: IProps) => {
  const handleClose = () => {
    reset()
    setOpen({status: false})
  }
  useEffect(() => {
    if (data) {
      setValue('flexible_days', data?.flexible_days?.toString() || '1')
      if (data.started_at && data.finished_at) {
        setValue('duration', {
          fromDate: dayjs(data.started_at)?.toDate(),
          toDate: dayjs(data.finished_at)?.toDate(),
        })
      } else {
        setValue('duration', {fromDate: new Date(), toDate: new Date()})
      }
      // setValue('duration.toDate', data.finished_at)
    }
  }, [data])
  const onSubmit = async (dataValue: any) => {
    try {
      const fromDate = new Date(dataValue.duration.fromDate)
      const toDate = new Date(dataValue.duration.toDate)
      const payload = {
        id: data.id,
        flexible_days: +dataValue.flexible_days,
        started_at: fromDate.toISOString(),
        finished_at: toDate.toISOString(),
        duration_type: data.duration_type,
      }
      await ClassesApi.updateClass(payload)
      toast.success('Update Class duration successfully!')
      fetchClasses()
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }
  const validationSchema = z.object({
    flexible_days: z
      .string()
      .regex(/^(?:[0-9]+)?$/, {message: VALIDATE_NUMBER})
      .refine(
        (val: any) => {
          if (Number(val) < 1) {
            return false
          }
          return true
        },
        {message: VALIDATION_MIN_EQUAL(1)}
      ),
    duration: z
      .object({
        fromDate: z.date().optional(),
        toDate: z.date().optional(),
      })
      .optional(),
  })
  const {control, setValue, reset, handleSubmit} = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  return (
    <SappModal
      title='Update Duration'
      open={open}
      handleClose={handleClose}
      confirmOnclose
      handleSubmit={handleSubmit((e) => {})}
      centered={true}
      showFooter={false}
      classBody='modal-footer d-block'
      customFooter={
        <div className='d-flex justify-content-end align-items-center gap-5'>
          {/* {data?.duration_type !== 'flexible' && (
            <div>
              {(() => {
                if (watch('duration')?.fromDate) {
                  return format(new Date(watch('duration')?.fromDate?.toISOString()), 'dd/MM/yyyy')
                }
                return ''
              })()}{' '}
              -{' '}
              {(() => {
                if (watch('duration')?.toDate) {
                  return format(new Date(watch('duration')?.toDate?.toISOString()), 'dd/MM/yyyy')
                }
                return ''
              })()}
            </div>
          )} */}
          <SAPPDialogButtonsCancelSubmit
            cancelClick={() => {
              handleClose()
            }}
            cancelButtonCaption={'Cancel'}
            okButtonCaption={'Save'}
            okOnClick={handleSubmit(onSubmit)}
            className='d-flex m-0'
            loading={false}
            disabled={false}
          />
        </div>
      }
      classNameFooter='border-0'
    >
      {data?.duration_type !== OPTIONS_OF_DURATION.valueFlexible ? (
        <RangeDateTimePicker control={control} name='duration' allowClear={false} />
      ) : (
        <HookFormTextField
          name='flexible_days'
          control={control}
          postFix={<div className='sapp-post-fix-text-field '>days</div>}
          placeholder=''
        />
      )}
    </SappModal>
  )
}
export default UpdateDuration
