import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import {format} from 'date-fns'
import {KTCardBody} from 'src/_metronic/helpers'

const headers = [
  {
    label: 'ID',
    className: 'min-w-100px',
  },
  {
    label: 'Kỳ Thi',
    className: 'min-w-500px',
  },
  {
    label: 'Ngày Thi Đầu',
    className: 'min-w-130px',
  },
  {
    label: 'Ngày thi cuối',
    className: 'min-w-130px',
  },
  {
    label: 'Ngày đăng ký cuối',
    className: 'min-w-130px',
  },
]
interface IProps {
  examList: any
  loading: boolean
  checkedList: any
  toggleCheck: any
  handlePaginationChange: any
}
// define headers

const TableExamCMA = ({
  examList,
  loading,
  checkedList,
  toggleCheck,
  handlePaginationChange,
}: IProps) => {
  let getExams = examList?.exams ?? []
  if (typeof getExams === 'string') {
    getExams = []
  }

  return (
    <KTCardBody className='px-0'>
      <SappTable
        headers={headers}
        loading={loading}
        data={examList?.exams}
        isCheckedAll={false}
        hasCheckAll={false}
        onChange={() => {}}
      >
        {loading ? (
          <>
            {/* {headers.map((header, i) => ( */}

            <LoadingTable headers={[...headers, 1]} />
            {/* ))} */}
          </>
        ) : (
          <>
            {getExams.map((e: any, index: number) => {
              const isChecked = checkedList[0]?.id?.includes(e.id)

              return (
                <tr key={e.id}>
                  <td>
                    <SAPPRadio
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(e)
                      }}
                    />
                  </td>
                  <td className='text-start'>{e?.code ?? '-'}</td>
                  <td className='text-start '>
                    <span className='sapp-table-title-des'>
                      {`${e?.season_description} `}
                      {(() => {
                        if (e?.season === null) {
                          return '-'
                        }
                        const date = new Date(e?.season)
                        return `${format(date, 'dd/MM/yyyy')}`
                      })() ?? '-'}
                    </span>
                  </td>
                  <td className='text-start'>
                    {(() => {
                      if (e.first_exam_date === null) {
                        return '-'
                      }
                      const date = new Date(e?.first_exam_date || 0)
                      return `${format(date, 'dd/MM/yyyy')}`
                    })() ?? '-'}
                  </td>
                  <td className='text-start'>
                    {(() => {
                      if (e.final_exam_date === null) {
                        return '-'
                      }
                      const date = new Date(e?.final_exam_date)
                      return `${format(date, 'dd/MM/yyyy')}`
                    })() ?? '-'}
                  </td>
                  <td className='text-start'>
                    {(() => {
                      if (e.last_registration_date === null) {
                        return '-'
                      }
                      const date = new Date(e?.last_registration_date)
                      return `${format(date, 'dd/MM/yyyy')}`
                    })() ?? '-'}
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={examList?.metadata?.page_index || 1}
        pageSize={examList?.metadata?.page_size || 10}
        totalItems={examList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}
export default TableExamCMA
