import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import Card from 'src/components/classes/profile/Card'
import {CLASS_PROFILE} from 'src/constants/classes'
import {PageLink} from 'src/constants'
import {TITLE_OPTIONS_CLASS, CLASS_PROFILE_URL} from 'src/constants/classes'
import {ITabs} from 'src/type'
import {IClassDetail} from 'src/type/classes'
import {ClassesApi} from 'src/apis/classes'
import RenderClassProfile from 'src/components/classes/profile/RenderClassProfile'
import './ClassDetail.scss'

const ClassDetail = () => {
  const [loading, setLoading] = useState(true)
  const [classDetail, setClassDetail] = useState<IClassDetail>()
  const {id} = useParams()
  const location = useLocation()

  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: 'LMS',
    },
    {
      link: `${PageLink.CLASSES}`,
      title: `${TITLE_OPTIONS_CLASS.listClasses}`,
    },
    {
      link: '',
      title: `${TITLE_OPTIONS_CLASS.classDetail}`,
    },
  ]

  // Config Tabs
  const tabs: ITabs[] = [
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.OVERVIEW}`,
      title: 'Overview',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.SETTING}`,
      title: 'Settings',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.STUDENTS}`,
      title: 'List Students',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.MENTORS}`,
      title: 'List Mentors',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.TEACHERS}`,
      title: 'List Teachers',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.EXAMINATION}`,
      title: 'Examination',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.MARK}`,
      title: 'Marks',
    },
  ]

  const getClassDetail = async () => {
    setLoading(true)
    try {
      const res = await ClassesApi.detail(id)
      setClassDetail(res?.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getClassDetail()
  }, [location.pathname.split(`/${id}/`)[1]])

  return (
    <PageLayouts
      pageTitle='Class Detail'
      breadcrumbs={breadcrumbs}
      classNameLayout='sapp-overflow-y-layout'
    >
      <Card classDetail={classDetail} tabs={tabs} loading={loading} />
      <RenderClassProfile
        classDetail={classDetail}
        setLoading={setLoading}
        loading={loading}
        setClassDetail={setClassDetail}
      />
    </PageLayouts>
  )
}

export default withAuthComponents(ClassDetail)
