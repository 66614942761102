import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { Control, Controller } from 'react-hook-form'
import SappLabel from '../label/SappLabel'
import GuidelineField from 'src/common/GuidelineField'
import ErrorMessage from 'src/common/ErrorMessage'
import { TimeIcon } from 'src/common/icons'

interface IProps {
  isListScreen?: boolean
  control: Control<any>
  defaultValue?: any
  name: string
  label?: string
  className?: string
  guideline?: string[]
  onChange?: (data: Date | null) => void
  required?: boolean
  allowClear?: boolean
  disabled?: boolean
  placeholder?: any
}

const { RangePicker } = DatePicker
const RangeDateTimePicker = ({
  control,
  defaultValue,
  name,
  label,
  className,
  guideline,
  required,
  allowClear,
  disabled = false,
  placeholder,
  isListScreen = false,
}: IProps) => {
  return (
    <>
      {label && <SappLabel label={label} required={required} />}

      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => {
          return (
            <>
              <RangePicker
                style={{ width: '100%' }}
                status={fieldState.error ? 'error' : undefined}
                placeholder={placeholder}
                ref={field.ref}
                name={field.name}
                format='DD/MM/YYYY'
                onBlur={field.onBlur}
                value={[
                  field?.value?.fromDate ? dayjs(field?.value?.fromDate) : null,
                  field?.value?.toDate ? dayjs(field?.value?.toDate) : null,
                ]} // Convert the date to dayjs object for the DateTimePicker
                onChange={(e: any) => {
                  if (e !== null) {
                    const fromDate = e?.[0]?.toDate()
                    const toDate = e?.[1]?.toDate()
                    field.onChange({ fromDate, toDate })
                  } else {
                    field.onChange({ fromDate: null, toDate: null })
                  }
                }}
                //   nextIcon={<></>}
                // separator={<div>-</div>}
                className={`createDateRangePicker ${className ?? ''} ${
                  isListScreen ? 'sapp-h-40' : 'sapp-h-45px'
                }`}
                allowClear={allowClear}
                disabled={disabled}
                suffixIcon={<TimeIcon />}
              />
              <div>
                <GuidelineField guideline={guideline} />
                <ErrorMessage>{fieldState.error?.message}</ErrorMessage>
              </div>
            </>
          )
        }}
      />
    </>
  )
}
export default RangeDateTimePicker
