import {zodResolver} from '@hookform/resolvers/zod'
import {capitalize, isEmpty} from 'lodash'
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {toast} from 'react-hot-toast'
import {useQueryClient} from 'react-query'
import {useLocation, useParams} from 'react-router-dom'
import {StaffAPI} from 'src/apis/staffs'
import {VerifyEmailOtp} from 'src/apis/type'
import {UsersAPI} from 'src/apis/user'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {DURATION_RESEND_EMAIL, VALIDATION_FILED} from 'src/constants'
import {DESCRIPTION_POPUPCONFIRM} from 'src/constants/lang'
import {useUserContext} from 'src/context/UserProvider'
import {useConfirm} from 'src/hooks/use-confirm'
import {z} from 'zod'
import UserOTP from './UserOTP'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  isActive: boolean
  setIsActive: Dispatch<SetStateAction<boolean>>
  currentEmail?: string
  setStaffDetail?: Dispatch<SetStateAction<any>>
  setStudentDetail?: Dispatch<SetStateAction<any>>
  setTeacherDetail?: Dispatch<SetStateAction<any>>
  id?: string
  refetch?: any
}

interface IInputProps {
  email: string
}

const ModalEditEmail = ({
  open,
  setOpen,
  setIsActive,
  currentEmail,
  isActive,
  setStaffDetail,
  setStudentDetail,
  setTeacherDetail,
  id,
  refetch,
}: IProps) => {
  const {getStaffDetail, getStudentDetail} = useUserContext()
  const {confirm, contextHolder} = useConfirm()
  const [durationResend, setDurationResend] = useState(DURATION_RESEND_EMAIL)
  const {id: paramId} = useParams()
  const isAllEmpty = (arr: string[] | undefined) => arr?.every((value) => value !== '')
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const location = useLocation()
  const {sendEmailOTP, sendEmailOTPUser} = useUserContext()
  const queryClient = useQueryClient()

  // Validate for input
  const validationSchema = z.object({
    email: z.string({required_error: VALIDATION_FILED}).email(),
  })

  // Using validate for input
  const {control, handleSubmit, watch, reset, getValues, setError, resetField} =
    useForm<IInputProps>({
      resolver: zodResolver(validationSchema),
      mode: 'onChange',
    })

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => setOpen(false),
    })
    setOtp(['', '', '', '', '', ''])
    reset()
    setIsActive(false)
  }
  const onSubmitChangeEmail = async () => {
    const email = getValues('email')
    try {
      if (
        location.pathname.split('/profile')?.[0] === '/staff' ||
        location.pathname.includes('/staffs')
      ) {
        await StaffAPI.changeEmail(id || paramId, email, otp.join('')).then((res) => {
          getStaffDetail(id || paramId).then((res) => setStaffDetail?.(res?.data))
          toast.success(res?.data?.message)
          reset()
          setOpen(false)
          setOtp(['', '', '', '', '', ''])
          refetch(true)
        })
      } else {
        await UsersAPI.changeEmail(id || paramId, email, otp.join('')).then((res) => {
          getStudentDetail(id || paramId).then((res) => {
            if (res.data.type === 'STUDENT') {
              setStudentDetail?.(res?.data)
            }
            if (res.data.type === 'TEACHER') {
              setTeacherDetail?.(res?.data)
            }
          })

          toast.success(res?.data?.message)
          reset()
          setOpen(false)
          refetch(true)
          setOtp(['', '', '', '', '', ''])
        })
      }
    } catch (err: any) {
      err?.response?.data?.error?.others?.forEach((e: any) => {
        const errorMessage = e?.errors?.[0]?.message

        setError(e.property, {message: capitalize(errorMessage)})
      }, {})
    }
  }

  useEffect(() => {
    async function sendOtpOnLoad() {
      let res: VerifyEmailOtp
      let userRes: VerifyEmailOtp
      try {
        if (
          location.pathname.split('/profile')?.[0] === '/staff' ||
          location.pathname.includes('/staffs')
        ) {
          res = await sendEmailOTP(id || paramId)
          if (res.success === true) {
            setIsActive(true)
            setDurationResend(DURATION_RESEND_EMAIL)
          }
        } else {
          userRes = await sendEmailOTPUser(id || paramId)
          if (userRes.success) {
            setIsActive(true)
            setDurationResend(DURATION_RESEND_EMAIL)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
    open === true && sendOtpOnLoad()
  }, [open])

  return (
    <>
      {contextHolder}
      <Modal dialogClassName='modal-dialog modal-dialog__edit-email' centered show={open}>
        {/* start:: header modal */}
        <div className='modal-header modal-header__edit-email'>
          <h2 className='mb-0'>Edit Email</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary h-100'
            onClick={handleCancel}
          >
            <i className='ki-outline ki-cross fs-1' />
          </div>
        </div>
        {/* end:: header modal */}

        {/* start:: body modal */}
        <div className='modal-body modal-body__edit-email'>
          {/* start:: textfield description */}
          <div className='modal-body__container d-flex flex-column fv-row fv-plugins-icon-container'>
            <HookFormTextField
              control={control}
              name='email'
              label='New Mail'
              placeholder='New Mail'
              required
              autofocus={true}
            />
            <UserOTP
              open={open}
              setOpen={setOpen}
              isActive={isActive}
              setIsActive={setIsActive}
              onSubmitChangeEmail={onSubmitChangeEmail}
              otp={otp}
              setOtp={setOtp}
              durationResend={durationResend}
              setDurationResend={setDurationResend}
              currentEmail={currentEmail}
            />
          </div>
          {/* end:: body modal */}
        </div>
        {/* start:: dialog buton cancel submit */}
        <div className='card-footer__otp d-flex py-5 px-9'>
          <ButtonSecondary
            title='Cancel'
            className='btn-active-light-primary me-5'
            onClick={handleCancel}
          />
          <ButtonPrimary
            title='Save'
            onClick={handleSubmit(onSubmitChangeEmail)}
            disabled={isEmpty(watch('email')) || !isAllEmpty(otp)}
          />
        </div>

        {/* end:: dialog buton cancel submit */}
      </Modal>
    </>
  )
}

export default React.memo(ModalEditEmail)
