import React from 'react'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import LoadingTable from './LoadingTable'
import { ITable } from 'src/type'
import { KTCardBody } from 'src/_metronic/helpers'

const SappBaseTable = ({
  headers,
  children,
  dataResponse,
  loading,
  handlePaginationChange,
  onChange,
  hasCheck,
  isCheckedAll = false,
  data,
}: ITable) => {
  return (
    // <div className='card card-flush border-gray-300 mt-3 sapp-overflow-y-layout'>
    // <div className='container'>
    <KTCardBody>
      <div className='row sapp-overflow-y-layout'>
        <SappTable
          headers={headers}
          loading={loading}
          data={data}
          isCheckedAll={isCheckedAll}
          onChange={onChange}
          hasCheck={hasCheck}
        >
          {loading ? (
            <>
              {headers?.map((header) => (
                <LoadingTable key={header.label} headers={headers} />
              ))}
            </>
          ) : (
            <>{children}</>
          )}
        </SappTable>
        <PagiantionSAPP
          currentPage={dataResponse?.metadata?.page_index || dataResponse?.meta?.page_index || 1}
          pageSize={dataResponse?.metadata?.page_size || dataResponse?.meta?.page_size || 10}
          totalItems={dataResponse?.metadata?.total_records || dataResponse?.meta?.total_records}
          handlePaginationChange={handlePaginationChange}
        />
      </div>
    </KTCardBody>
    // </div>
    // </div>
  )
}

export default SappBaseTable
