import {uniqueId} from 'lodash'
import React, {useEffect, useLayoutEffect, useState} from 'react'
import SappModal from 'src/components/base/SappModal'
import Tab from '../create-tabs/Tabs'
import {CoursesAPI} from 'src/apis/courses'
import VideoSetting from '../create-tabs/tabVideo/videoSetting'
import {changeNodeAtPath} from '../tree-course'
import {useConfirm} from 'src/hooks/use-confirm'
import {CourseCondition} from 'src/type/courses'
interface Iprops {
  open: boolean
  setOpen: any
  course_section_id: string
  edit?: boolean
  setTreeData?: any
  path?: any
  treeData?: any
  nodeData?: any
  onlyEdit?: boolean
  courseCondition: CourseCondition
}

const CreateTabs = ({
  open,
  setOpen,
  course_section_id,
  setTreeData,
  path,
  treeData,
  nodeData,
  courseCondition,
}: Iprops) => {
  const [data, setData] = useState<
    {
      tabId: string
      value?: {
        tab_name?: string
        document?: [
          {
            type?: string
            payload?: any
            DocId?: string
          }
        ]
      }
      creating?: boolean
      edit?: boolean
    }[]
  >([])

  const [fistLoad, setFistLoad] = useState(true)
  const [tabActive, setTabActive] = useState<string>('')
  const {contextHolder} = useConfirm()
  useLayoutEffect(() => {
    async function getTabIds() {
      try {
        setFistLoad(true)
        const res = await CoursesAPI.getTabByActivity(course_section_id)
        const newData: any = []
        for (let e of res.data) {
          newData.push({
            tabId: e.id,
            value: {
              tab_name: undefined,
              document: undefined,
            },
          })
        }
        setData((prev) => {
          setFistLoad(false)
          return newData
        })
      } catch (err) {
        setFistLoad(false)
        console.error(err)
      }
    }
    if (course_section_id) {
      getTabIds()
    }
  }, [course_section_id])
  // set lại total_tab mỗi khi thay đổi data
  useEffect(() => {
    if (data && path) {
      const listTab = data.filter((e) => !e.creating)
      const newNode = {...nodeData, total_tab: listTab.length}
      const getNodeKey = ({treeIndex}: any) => treeIndex
      setTreeData(changeNodeAtPath({treeData: treeData, path, getNodeKey, newNode}))
    }
  }, [path, data])

  // Nếu chưa có tab thì default add new tab
  useEffect(() => {
    if (data?.length === 0) {
      handleAddTab()
    }
  }, [data])

  const handleAddTab = () => {
    const newId = uniqueId('tab')

    setData((e: any) => {
      const arr = [...e]
      arr.push({
        tabId: newId,
        value: {
          tab_name: undefined,
          document: [],
        },
        creating: true,
      })
      return arr
    })
    setTabActive(newId)
  }

  const handleClose = () => {
    // let body = ['Bạn có chắc chắn muốn quay lại không?']
    // confirm({
    //   okButtonCaption: 'Yes',
    //   cancelButtonCaption: 'No',
    //   body,
    //   onClick: async () => {
    //     setOpen({status: false, course_section_id: ''})
    //     setData([])
    //     setFistLoad(true)
    //   },
    // })
    setOpen({status: false, course_section_id: ''})
    setData([])
    setFistLoad(true)
  }
  const [openSetting, setOpenSetting] = useState(false)
  return (
    <React.Fragment>
      {/* <Spin spinning={true}> */}
      {contextHolder}
      <SappModal
        // confirmOnclose
        key={'create-tab-modal'}
        enforceFocus={false}
        open={open}
        handleClose={handleClose}
        title={''}
        cancelButtonCaption='Cancel'
        okButtonCaption='Save'
        //   handleSubmit={handleSubmit(onSubmit)}
        showFooter={false}
        dialogClassName={
          'm-0 modal-dialog-scrollable sapp-modal-createTab modal-fullscreen modal-fullscreen-form modal-tabs'
        }
        classNameBody={'sapp-m-h-unset'}
        showClassHeightBody={false}
        showIconDelete
        // showCloseButton={false}
        // customFooter={CustomeFooter()}
        customTitle={(title) => (
          // <div className='d-flex justify-content-center flex-grow-1'>
          <div className='hover-scroll-x pt-6 sapp-tabs-nav-container mb-0'>
            <div className='d-grid'>
              <ul className='nav nav-tabs flex-nowrap text-nowrap border-0 '>
                {data.length > 0 &&
                  data.map((e, i) => {
                    return (
                      <li className='nav-item' key={i}>
                        <a
                          className={`btn rounded-bottom-0 ${
                            i === 0 && tabActive !== e.tabId ? 'sapp-tab--first' : 'sapp-tab-btn'
                          } ${tabActive === e.tabId && 'active sapp-text-active'}`}
                          data-bs-toggle='tab'
                          href={`#kt_tab_pane_${i}`}
                          onClick={() => setTabActive(e.tabId)}
                        >
                          {`Tab ${i + 1}`}
                        </a>
                      </li>
                    )
                  })}
                {
                  <div
                    className='btn sapp-tab-btn text-decoration-underline'
                    onClick={handleAddTab}
                  >
                    + New Tab
                  </div>
                }
              </ul>
            </div>
          </div>
        )}
      >
        {!fistLoad && (
          <Tab
            closeModal={handleClose}
            handleAddTab={handleAddTab}
            data={data}
            courseCondition={courseCondition}
            isFistLoad={fistLoad}
            setTabActive={setTabActive}
            tabActive={tabActive}
            setData={setData}
            course_section_id={course_section_id}
          />
        )}
      </SappModal>
      <VideoSetting open={openSetting} setOpen={setOpenSetting} />
      {/* </Spin> */}
    </React.Fragment>
  )
}
export default CreateTabs
