export const LANG_SIGNIN = {
  signIn: 'Sign In',
  SAPPAcademy: 'SAPP Academy Operations',
  orWithEmail: 'Or with email',
  email: 'Email',
  password: 'Password',
  forgotPassword: 'Forgot Password ?',
  notMember: 'Not a Member yet?',
  terms: 'Terms',
  contactUs: 'Contact Us',
}

export const LANG_SIDEBAR = {
  lms: 'LMS',
  dashboard: 'Dashboard',
  news: 'Tin tức',
  listNews: 'Danh sách',
  createNews: 'Tạo tin tức',
  courses: 'Courses',
  test: 'Test',
  caseStudy: 'Case Study',
  topicList: 'Item Set',
  topic: 'Item Set Detail',
  questionBank: 'Question Banks',
  dictionary: 'Dictionary',
  supportCenter: 'Trung tâm hỗ trợ',
  supportOverview: 'Báo cáo',
  supportTickets: 'Tickets',
  supportFAQs: 'FAQs',
  notifications: 'Notifications',
  sendNotifications: 'Gửi thông báo',
  listNotifications: 'Notification List',
  listGroups: 'Group List',
  staffs: 'Staffs',
  listStaffs: 'List Staffs',
  createStaffs: 'Create Staffs',
  students: 'Students',
  listStudents: 'List Students',
  createStudents: 'Create Student',
  teachers: 'Teachers',
  listTeacher: 'List Teachers',
  createTeacher: 'Create Teachers',
  admin: 'Admin',
  operations: 'Operations',
  roles: 'Roles',
  permissions: 'Permissions',
  setting: 'Setting',
  pro: 'Pro',
  myProfile: 'My Profile',
  mode: 'Mode',
  light: 'Light',
  dark: 'Dark',
  system: 'System',
  signOut: 'Sign Out',
  importLog: 'Import Report',
  createStory: 'Case Study Detail',
  listStories: 'List Case Study',
  listCertificates: 'Certificate List',
  commingSooon: 'Comming soon',
  addItemSet: 'Add Item Set',
  addSingleQuestion: 'Add single question',
  questionList: 'Question List',
  supporterGroupList: 'Supporter Group List',
  marketing: 'Marketing',
  eventTest: 'Event Test List',
  createEvent: 'Create Event Test',
  allParticipant: 'All Participants',
}

export const LANG_COURSES = {
  addCourses: 'Create Course',
  code: 'CODE',
  courseName: 'COURSE NAME',
  subjects: 'SUBJECTS',
  action: 'ACTION',
  pleaseSelect: 'Please Select',
  selectCourse: 'Select Course',
  selectSection: 'Select Section',
  selectSubsection: 'Select Subsection',
  seclectUnit: 'Seclect Unit',
  seclectActivity: 'Seclect Activity',
  courseList: 'Course List',
}
export const LANG_CLASSES = {
  addClass: 'Create Class',
  importClass: 'Import Class',
  className: 'Class Name',
}

export const LANG_ENTRANCE_TEST = {
  addEntranceTest: 'Create Entrance Test',
  entranceTestName: 'Entrance Test Name',
  addQuestion: 'Add Question',
  LIST_TEST: 'Entrance Test List',
  addParticipant: 'Add Participants',
}

export const LANG_COURSE_CATEGORY = {
  categoryName: 'CATEGORY NAME',
  description: 'DESCRIPTION',
  action: 'ACTION',
}

export const BUTTON_TEXT = {
  SAVE: 'Save',
  SEARCH: 'Search',
}

export const LANG_PLACEHOLDER = {
  FULLNAME: 'Full Name',
}

export const LANG_CERTIFICATES = {
  addTemplate: 'Create Template',
  importCertificate: 'Import Certificate',
  CertificatesName: 'Certificate Name',
}

export const DESCRIPTION_POPUPCONFIRM = 'Bạn có chắc chắn muốn hủy không?'

export const LANG_USER = {
  TEST_ACCOUNT: 'Test Account',
}
