import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PageLayouts from 'src/components/layout/PageLayouts'
import { useCourseContext } from 'src/context/CourseProvider'
import TestInfo from './TestInfo'
import ListSection from './ListSection'
import { EventTestAPI } from 'src/apis/event-test'

interface ISteps {
  link: string
  status: string
  title: string
}

const EventTestDetail = () => {
  const { setId } = useCourseContext()
  const { id } = useParams()
  const navigate = useNavigate() // hook để điều hướng lập trình
  const [searchParams, setSearchParams] = useSearchParams() // hook để lấy và cập nhật các
  const [loading, setLoading] = useState(false)

  const [newStep, setNewStep] = useState<any>([
    {
      title: 'Test Info',
      status: 'current',
    },
    {
      title: 'List Section',
      status: '',
    },
  ])

  useEffect(() => {
    if (id) {
      setId(id as string)
      // set default page bằng tham số page
      if (searchParams.has('page')) {
        const index = Number(searchParams.get('page'))
        setNewStep((step: any[]) => {
          const _new = step.map((e, idx) => {
            if (index > idx) {
              return { ...e, status: 'active' }
            }
            if (index === idx) {
              return { ...e, status: 'current' }
            }
            if (index < idx) {
              return { ...e, status: '' }
            }
          })
          return _new
        })
        searchParams.delete('page')
        setSearchParams(searchParams)
        navigate('.', { replace: true })
      }
    }
  }, [id, newStep])

  const handleGotoStep = (index: number) => {
    const _new = newStep.map((e: any, idx: number) => {
      if (index > idx) {
        return { ...e, status: 'active' }
      }
      if (index === idx) {
        return { ...e, status: 'current' }
      }
      if (index < idx) {
        return { ...e, status: '' }
      }
    })
    setNewStep(_new)
  }

  const renderStep = (step: ISteps, index: number) => {
    switch (step.status) {
      case 'current':
        return (
          <div key={index}>
            {index === 0 && (
              <TestInfo
                handleGotoStep={handleGotoStep}
                listInfo={listInfo}
                setStep={setNewStep}
                step={newStep}
                loading={loading}
                setLoading={setLoading}
              />
            )}
            {index === 1 && (
              <ListSection
                listInfo={listInfo}
                handleGotoStep={handleGotoStep}
                setStep={setNewStep}
                step={newStep}
                loading={loading}
                setLoading={setLoading}
              />
            )}
          </div>
        )
      default:
        return null // Handle other statuses if needed
    }
  }

  const [listInfo, setListInfo] = useState<any>()

  async function eventTestDetail(id: string) {
    const res = await EventTestAPI.getDetail(id)
    setListInfo(res?.data)
  }

  useEffect(() => {
    if (id !== 'undefined' && id) {
      eventTestDetail(id)
    }
  }, [id])

  return (
    <PageLayouts
      breadcrumbs={[]}
      pageTitle=''
      showSideBar={false}
      titleTab='Event Test'
      removePadding={true}
      backgroudColor='bg-white'
      showFooter={false}
      classNameLayout='pt-20'
      // backgroudColor={`${newStep[0]?.status === 'current' ? '' : 'bg-white'}`}
    >
      {newStep.map((step: ISteps, index: number) => renderStep(step, index))}
    </PageLayouts>
  )
}
export default EventTestDetail
