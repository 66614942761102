// export const convertLocalTimeToUTC = (currentTime: Date) => {
//     const offsetMinutes = currentTime.getTimezoneOffset()
//     const utcTime = new Date(currentTime.getTime() + offsetMinutes * 60 * 1000)

//     return utcTime
//   }

export const convertUTCToLocalTime = (utc_time: Date | string) => {
  return new Date(utc_time)
}
