import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { isUndefined } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import { uploadFile, UsersAPI } from 'src/apis/user'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import HeaderTab from 'src/components/base/HeaderTab'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import ModalEditEmail from 'src/components/user-list/components/user-edit-modal/ModalEditEmail'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  STATUS_FORM,
  TEACHER_PROFILE,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PHONE,
  VALIDATION_FILED,
} from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM, LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { IUpdateTeacher } from 'src/pages/teachers/CreateAndUpdateTeacher'
import { IError, Role } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import { phoneRegExp, sizeInBytes, usernamePattern } from 'src/utils'
import { z } from 'zod'
const { Option } = Select

interface IInputProps {
  full_name: string
  email: string
  username: string
  status: string
  phone: number
  password: string
  confirmPassword: string
  avatar: File
  sex: string
  job_title: string
  facebook: string
  youtube: string
  linkedin: string
  describe: string
}

interface IProps {
  teacherDetail: IStudentDetail | undefined
  setTeacherDetail: Dispatch<SetStateAction<any>>
  loading: boolean
}

const EditProfileTeacher = ({ teacherDetail, setTeacherDetail }: IProps) => {
  const navigate = useNavigate()

  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const { id } = useParams()
  const [openEditEmail, setOpenEditEmail] = useState(false)
  const { confirm, contextHolder } = useConfirm()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const userContact = teacherDetail?.user_contacts?.find((user) => user.is_default)
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)
  const allowRenderEditEmail = profileMe?.roles?.some((role: Role) => {
    return (
      hasPermission(role, TITLE_GR.PUT_CHANGE_EMAIL_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
    )
  })
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_GR.EDIT_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${TEACHER_PROFILE}/${id}/overview`),
    })
  }

  const schema = z.object({
    full_name: z
      .string({ required_error: VALIDATION_FILED })
      .min(3, { message: VALIDATE_MIN_FULLNAME })
      .max(100, { message: VALIDATE_MAX_FULLNAME }),
    email: z.string({ required_error: VALIDATION_FILED }).email(),
    status: z.string(),
    phone: z
      .string({ required_error: VALIDATION_FILED })
      .regex(new RegExp(phoneRegExp), { message: VALIDATE_PHONE }),
    avatar: z.any(),
    sex: z.string(),
    username: z
      .string({ required_error: VALIDATION_FILED })
      .regex(new RegExp(usernamePattern), {
        message:
          'Username must have at least 6 characters without Vietnamese and space between words',
      })
      .min(6, { message: VALIDATE_MIN_USERNAME })
      .max(40, { message: 'Username Must Be Shorter Than Or Equal To 40 Characters' }),
    job_title: z.string().optional(),
    facebook: z.string().optional(),
    youtube: z.string().optional(),
    linkedin: z.string().optional(),
    describe: z.string().optional(),
  })

  const { control, handleSubmit, setValue, setError } = useForm<IInputProps>({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
  })

  const isValidHttpUrl = (url: string) => {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
    return pattern.test(url)
  }

  const onSubmit = async (data: IInputProps) => {
    const {
      email,
      full_name,
      phone,
      username,
      status,
      sex,
      avatar,
      job_title,
      facebook,
      youtube,
      linkedin,
      describe,
    } = data

    if (facebook && !isValidHttpUrl(facebook)) {
      setError('facebook', { message: 'Invalid Value' })
      return
    }

    if (linkedin && !isValidHttpUrl(linkedin)) {
      setError('linkedin', { message: 'Invalid Value' })
      return
    }

    if (youtube && !isValidHttpUrl(youtube)) {
      setError('youtube', { message: 'Invalid Value' })
      return
    }
    // Prevent form submission
    if (!isUndefined(avatar) && (avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE))
      return

    if (!isUndefined(avatar)) {
      await uploadFile({ userId: teacherDetail?.id, avatar: avatar })
    }

    setLoadingSubmit(true)
    try {
      const paramsUpdate: IUpdateTeacher = {
        email: email.toLowerCase(),
        full_name: full_name,
        phone: phone,
        username: username,
        status: status,
        sex: sex || null,
        avatar: null,
        job_title,
        facebook,
        youtube,
        linkedin,
        describe,
      }

      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }

      await UsersAPI.update({
        id: teacherDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update SuccessFully!')
          navigate(`${TEACHER_PROFILE}/${teacherDetail?.id}/overview`)
        })
        .catch((err: any) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message

            setError(e.property, { message: errorMessage })
          }, {})
        })
    } catch (error) {
      setLoadingSubmit(false)
    } finally {
      setLoadingSubmit(false)
    }
  }

  useEffect(() => {
    if (!teacherDetail?.id) return

    setValue('email', userContact?.email ?? '')
    setValue('full_name', teacherDetail?.detail?.full_name ?? '')
    setValue('phone', userContact?.phone ?? '')
    setValue('status', teacherDetail?.status ?? '')
    setValue('username', teacherDetail?.username ?? '')
    setValue('sex', teacherDetail?.detail?.sex ?? '')
    setValue('job_title', teacherDetail?.detail?.job_title ?? '')
    setValue('facebook', teacherDetail?.detail?.facebook ?? '')
    setValue('youtube', teacherDetail?.detail?.youtube ?? '')
    setValue('linkedin', teacherDetail?.detail?.linkedin ?? '')
    setValue('describe', teacherDetail?.detail?.describe ?? '')
  }, [
    setValue,
    userContact?.email,
    teacherDetail?.detail?.full_name,
    userContact?.phone,
    teacherDetail?.detail?.sex,
    teacherDetail?.id,
    teacherDetail?.status,
    teacherDetail?.username,
  ])

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <HeaderTab title='Profile Details' />

        <div className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-semibold fs-6'>Avatar</label>
                <div className='col-lg-9'>
                  <SAPPHookUploadFile
                    name='avatar'
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    imagePreview={
                      teacherDetail?.detail?.avatar?.['150x150'] ??
                      teacherDetail?.detail?.avatar?.ORIGIN ??
                      avatar
                    }
                    accept={ACCEPT_UPLOAD_MIME_TYPE}
                    maxFileSize={DEFAULT_MAX_FILE_SIZE}
                    setShowAvatarDefault={setShowAvatarDefault}
                    removeAvatar={avatar}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                  {LANG_PLACEHOLDER.FULLNAME}
                </label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='full_name'
                    control={control}
                    placeholder={LANG_PLACEHOLDER.FULLNAME}
                    defaultValue={teacherDetail?.detail?.full_name}
                    required
                    guideline={GUIDELINE_FULLNAME}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                  Username
                </label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='username'
                    control={control}
                    placeholder='Username'
                    defaultValue={teacherDetail?.username}
                    disabled
                    required
                    guideline={GUIDELINE_USERNAME}
                  />
                </div>
              </div>

              <div className='row mb-md-6 mb-0'>
                <label className='col-xl-3 col-lg-3 col-form-label required fw-semibold fs-6'>
                  Email
                </label>

                <div className='col-xl-8 col-lg-7 col-md-10 fv-row'>
                  <HookFormTextField
                    name='email'
                    control={control}
                    placeholder='Email'
                    defaultValue={teacherDetail?.detail?.email}
                    required
                    disabled
                    guideline={GUIDELINE_EMAIL}
                  />
                </div>
                <div className='col-xl-1 col-lg-2 col-md-2 d-flex justify-content-end mt-md-0 mt-3'>
                  <ButtonPrimary
                    title='Edit'
                    disabled={!allowRenderEditEmail}
                    className='sapp-p-button--custom'
                    onClick={() => setOpenEditEmail(true)}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Phone</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='phone'
                    control={control}
                    placeholder='Phone Number'
                    defaultValue={teacherDetail?.detail?.phone}
                    required
                    disabled
                    guideline={GUIDELINE_PHONE}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Status</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    name='status'
                    control={control}
                    classNameHeight='sapp-h-45px'
                    defaultValue={teacherDetail?.status}
                    disabled={!allowRenderEdit}
                  >
                    {STATUS_FORM.map((status) => (
                      <Option key={status.label} value={status.value}>
                        {status.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-semibold fs-6'>Gender</label>

                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    name='sex'
                    control={control}
                    classNameHeight='sapp-h-45px'
                    defaultValue={teacherDetail?.detail?.sex ?? ''}
                    placeholder='Please select'
                    disabled={!allowRenderEdit}
                  >
                    {GENDER_FORM.map((status) => (
                      <Option key={status.label} value={status.value}>
                        {status.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Job Title' className='col-lg-3' />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='job_title'
                    control={control}
                    placeholder='Job Title'
                    defaultValue={teacherDetail?.detail?.job_title}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Facebook Link' className='col-lg-3' />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='facebook'
                    control={control}
                    placeholder='Facebook Link'
                    defaultValue={teacherDetail?.detail?.facebook}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Youtube Link' className='col-lg-3' />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='youtube'
                    control={control}
                    placeholder='Youtube Link'
                    defaultValue={teacherDetail?.detail?.youtube}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='LinkedIn' className='col-lg-3' />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    name='linkedin'
                    control={control}
                    placeholder='LinkedIn'
                    defaultValue={teacherDetail?.detail?.linkedin}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <SappLabel label='Describe' className='col-lg-3' />
                <div className='col-lg-9 fv-row'>
                  <HookFormEditor
                    control={control}
                    name='describe'
                    placeholder=''
                    defaultValue={teacherDetail?.detail?.describe}
                    resourceLocation={RESOURCE_LOCATION.COURSE_SECTION}
                    object_id={''}
                    setDataFile={() => {}}
                    disabled={!allowRenderEdit}
                  />
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <ButtonSecondary
                title='Discard'
                className='btn-active-light-primary me-2'
                onClick={hanleCancel}
              />
              <ButtonPrimary
                title='Save Changes'
                onClick={handleSubmit(onSubmit)}
                loading={loadingSubmit}
                // disabled={!allowRenderEdit}
              />
            </div>
          </div>
        </div>
        <ModalEditEmail
          open={openEditEmail}
          setOpen={setOpenEditEmail}
          isActive={isActive}
          setIsActive={setIsActive}
          currentEmail={userContact?.email}
          setTeacherDetail={setTeacherDetail}
        />
      </div>
    </>
  )
}

export default EditProfileTeacher
