import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { ITabs } from 'src/type'
import axios from 'axios'
import withAuthComponents from 'src/components/auth/with-auth-components'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactNode, useEffect, useState } from 'react'
import { ImportLogAPI } from 'src/apis/import-log'
import { KTIcon } from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import {
  Abstract14Icon,
  ArrowDownSquareIcon,
  ArrowLeftSquareIcon,
  DownloadIcon,
  ShieldCrossIcon,
  ShieldTickIcon,
} from 'src/common/icons'
import { UserExportHelper } from 'src/helper/export'
import toast from 'react-hot-toast'
import ResultDetailCheckDuplicateContact from './ResultImportDetail'
import { Button } from 'bootstrap'

interface FileData {
  file_type?: string
  file_url?: string
  file_key?: string
  file_name?: string
  content_type?: string
}

enum EXPORT_TYPE {
  ALL = 'ALL',
  DUPLICATE = 'DUPLICATE',
  UNDUPLICATE = 'UNDUPLICATE',
}

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.marketing,
  },
  {
    link: `${PageLink.CHECK_DUPLICATE_CONTACT}`,
    title: 'Result Check Duplicate Contacts',
  },
]

const ResultCheckDuplicateContact = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [resultFile, setResultFile] = useState<FileData>({})
  const [recordExists, setRecordExists] = useState<Object[]>([])
  const [recordNotExists, setRecordNotExists] = useState<Object[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [collapseRecordExists, setCollapseRecordExists] = useState<boolean>(false)
  const [collapseRecordNotExists, setCollapseRecordNotExists] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const importLog = await ImportLogAPI.getImportLogsById({ id: params.id ?? '' })
        const result = importLog?.data?.result ? JSON.parse(importLog?.data?.result) : null
        const resultFile = importLog.data.files.find((e: FileData) => e.file_type === 'REPORT')
        if (result) {
          setTotalRows(result?.totalRows)
        }
        const jsonData = await axios.get(resultFile?.file_url)
        if (!jsonData?.data) return
        setRecordExists(jsonData?.data?.recordExists)
        setRecordNotExists(jsonData?.data?.recordNotExists)
        setResultFile(resultFile)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [params.id])

  const handleCheckOthers = () => {
    navigate(`${PageLink.CHECK_DUPLICATE_CONTACT}`)
  }

  const handleExportExcel = (type: string) => {
    const exportConfig: Record<string, any> = {
      [EXPORT_TYPE.DUPLICATE]: {
        data: recordExists,
        suffix: '_duplicate.xlsx',
      },
      [EXPORT_TYPE.ALL]: {
        data: [...recordExists, ...recordNotExists],
        suffix: '.xlsx',
      },
      [EXPORT_TYPE.UNDUPLICATE]: {
        data: recordNotExists,
        suffix: '_unduplicate.xlsx',
      },
    }
    const config = exportConfig[type]
    if (!config) return

    const { data, suffix } = config
    let baseName = resultFile.file_name || ''
    baseName = baseName.split('.').slice(0, -1).join('.')
    const fileName = `${baseName}${suffix}`
    try {
      if (data.length > 0) {
        UserExportHelper.exportDataToExcel(fileName, 'Results', data)
      } else {
        toast.error('No data to export')
      }
    } catch (error) {
      console.error('Error export data:', error)
    }
  }

  return (
    <PageLayouts pageTitle={'Check Duplicate Contacts'} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='ms-4 me-4 ps-5 pe-5 pt-5 pb-4 d-flex justify-content-between flex-center'>
          <ItemHeader icon={<Abstract14Icon />} title='Have Checked' total={totalRows} />
          <div className='d-flex justify-content-between flex-center'>
            <ButtonIcon
              title={'Check Others'}
              className='w-10 btn-sapp-filter px-3 py-3 sapp-font-import-student sapp-color-import-student'
              onClick={() => handleCheckOthers()}
              isListScreen
            >
              <KTIcon
                iconName='file-up'
                className='fs-3 pe-3 sapp-color-import-student'
                iconType='outline'
              />
            </ButtonIcon>
            <ButtonIcon
              className='ms-4'
              isListScreen
              onClick={() => handleExportExcel(EXPORT_TYPE.ALL)}
            >
              <DownloadIcon color='white' width='15' height='13.75' />
              <span className='ms-2'>Result</span>
            </ButtonIcon>
          </div>
        </div>
      </div>

      <div className='card mt-5'>
        <div className='card-border-bottom ms-4 me-4 ps-5 pe-5 pt-5 pb-4'>
          <div className='d-flex justify-content-between flex-center'>
            <ItemHeader
              icon={<ShieldCrossIcon />}
              title='Duplicate Contacts'
              total={recordExists?.length}
            />
            <ActionHeader
              showCollage={collapseRecordExists}
              onClickexportExcel={() => handleExportExcel(EXPORT_TYPE.DUPLICATE)}
              onClickCollage={() => setCollapseRecordExists((prevState) => !prevState)}
            />
          </div>
        </div>
        {recordExists && !collapseRecordExists && (
          <ResultDetailCheckDuplicateContact loading={loading} data={recordExists} />
        )}
      </div>

      <div className='card mt-5'>
        <div className='card-border-bottom ms-4 me-4 ps-5 pe-5 pt-5 pb-4'>
          <div className='d-flex justify-content-between flex-center'>
            <ItemHeader
              icon={<ShieldTickIcon />}
              title='Unduplicate Contacts'
              total={recordNotExists?.length}
            />
            <ActionHeader
              showCollage={collapseRecordNotExists}
              onClickexportExcel={() => handleExportExcel(EXPORT_TYPE.UNDUPLICATE)}
              onClickCollage={() => setCollapseRecordNotExists((prevState) => !prevState)}
            />
          </div>
        </div>
        {recordNotExists && !collapseRecordNotExists && (
          <ResultDetailCheckDuplicateContact loading={loading} data={recordNotExists} />
        )}
      </div>
    </PageLayouts>
  )
}

const ItemHeader = ({ icon, title, total }: { icon: ReactNode; title: string; total: number }) => {
  return (
    <div className='d-flex align-items-center sapp-text-light-inverse fw-semibold'>
      {icon}
      <div className='ms-5 d-flex flex-column'>
        <span>{title}</span>
        <span className='fw-bold fs-5 sapp-text-gray-detail'>{total || '--'}</span>
      </div>
    </div>
  )
}

const ActionHeader = ({
  showCollage,
  onClickexportExcel,
  onClickCollage,
}: {
  showCollage: boolean
  onClickexportExcel: () => void
  onClickCollage: () => void
}) => {
  return (
    <div className='d-flex'>
      <div className='cursor-pointer pe-8' onClick={onClickexportExcel}>
        <DownloadIcon />
      </div>
      <div className='cursor-pointer' onClick={onClickCollage}>
        {showCollage ? <ArrowLeftSquareIcon /> : <ArrowDownSquareIcon />}
      </div>
    </div>
  )
}

export default withAuthComponents(ResultCheckDuplicateContact)
