import SappDrawer from 'src/components/base/SappDrawer'
import Accodian from './AccodianItem'
import {useEffect, useState} from 'react'
import {ClassesApi} from 'src/apis/classes'
import {TreeHelper} from 'src/helper/tree'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import toast from 'react-hot-toast'

interface IProps {
  open: boolean
  setOpen: any
  data?: any
  title?: string
  type?: string
  id?: string
  exceptedSections?: any
  classId?: string
  refetch?: any
  studentId?: any
  isEnded?: boolean
}

interface ICourseSection {
  checked: boolean
  children: Array<any>
  code: string
  id: string
  is_original: boolean
  name: string
  is_excepted: boolean
}

const ModalProcessing = ({
  open,
  setOpen,
  title = 'Learning Progress',
  type = 'process',
  id,
  exceptedSections,
  classId,
  refetch,
  studentId,
  isEnded,
}: IProps) => {
  const [checkedAll, setCheckedAll] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isReset, setIsReset] = useState<boolean>(false)
  const checkAll = (status: boolean) => {
    setCheckedAll(status)
    // let checkedAll = true
    for (let e of explorerData) {
      toggleChecked(explorerData, e.id, status)
    }
    return true
  }
  const isLearning = title === 'Learning Progress'
  // const handleConvertData = (data: any) => {
  //   const newData = [...data]
  //   for (let e of newData) {
  //     for (let el of e?.children) {
  //       if (el?.course_learning_outcome) {
  //         el.children = [
  //           ...el.children,
  //           {
  //             ...el?.course_learning_outcome,
  //             children: [],
  //             course_section_type: TYPE_COURSE.LEARNINGOUTCOME,
  //           },
  //         ]
  //       }
  //     }
  //   }
  //   return newData
  // }

  const [explorerData, setExplorerData] = useState<any>([])

  const handleChecked = (e: string, status: boolean) => {
    setExplorerData((prev: any) => {
      const oldData = [...prev]
      const newData = toggleChecked(oldData, e, status)
      return newData
    })
  }
  // A function that recursively finds check if all node is checked
  function findNodeUnChecked(data: any): any {
    for (let node of data) {
      if (node.checked === false) {
        return false
      } else if (node.children && node.children.length > 0) {
        let result = findNodeUnChecked(node.children)
        if (!result) {
          return result
        }
      }
    }
    return true
  }
  function getUnchecked(data: any): any {
    // Initialize an empty array to store the results
    let result = []
    // Loop through each element in the data
    for (let element of data) {
      // Check if the element has checked = false
      if (!element.checked) {
        // Push the element to the result array
        result.push(element.id)
      }
      // Check if the element has children
      if (element.children) {
        // Recursively call the function on the children and concatenate the result array
        result = result.concat(getUnchecked(element.children))
      }
    }
    // Return the result array
    return result
  }
  // A function that takes an array of data and an id of a node
  function toggleChecked(data: any, id: string, checked: boolean) {
    // A helper function that recursively finds the node with the given id and returns it
    function findNode(data: any, id: string): any {
      for (let node of data) {
        if (node.id === id) {
          return node
        } else if (node.children && node.children.length > 0) {
          let result = findNode(node.children, id)
          if (result) {
            return result
          }
        }
      }
      return null
    }

    // A helper function that recursively updates the checked status of the node and its children
    function updateNode(node: any, checked: boolean) {
      node.checked = checked
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          updateNode(child, checked)
        }
      }
    }

    // A helper function that recursively updates the checked status of the node and its ancestors
    function updateAncestors(data: any, node: any) {
      if (node.parent_id) {
        let parent = findNode(data, node.parent_id)
        if (parent) {
          // If at least one child of the parent are checked, then the parent is checked
          // Otherwise, the parent is not checked
          let oneChecked = false
          for (let child of parent.children) {
            if (child.checked) {
              oneChecked = true
              break
            }
          }
          parent.checked = oneChecked
          // Repeat the process for the parent's parent
          updateAncestors(data, parent)
        }
      }
    }

    // Find the node with the given id
    let node = findNode(data, id)
    if (node) {
      // Toggle the checked status of the node
      //   let checked = !node.checked
      // Update the node and its children
      updateNode(node, checked)
      // Update the node and its ancestors
      updateAncestors(data, node)
    }
    return data
  }
  const handleSubmit = async () => {
    if (classId && !studentId) {
      setLoading(true)
      const payload = {
        excepted_course_section_ids: [...getUnchecked(explorerData)],
      }

      try {
        await ClassesApi.editCourseContentClass(payload, classId)
        refetch()
        toast.success('Update successful')
        setOpen({status: false})
      } catch (err) {
        setIsReset(true)
      } finally {
        setLoading(false)
      }
    } else if (classId && studentId) {
      setLoading(true)
      const payload = {
        excepted_course_section_ids: [...getUnchecked(explorerData)],
      }

      try {
        await ClassesApi.editCourseContentStudent(payload, classId, studentId)
        // refetch()
        toast.success('Update successful')
        setOpen({status: false})
      } catch (err) {
        console.error(err)
        setIsReset(true)
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    // if (id) {
    async function fetchCourseList() {
      if (id) {
        setLoading(true)
        try {
          let resExcept: any
          if (studentId && classId) {
            const res = await ClassesApi.getExceptedSectionStudent({
              id: classId,
              student_id: studentId,
            })

            resExcept = res.data?.course_section_data?.map((course: ICourseSection) => ({
              ...course,
              checked: course?.is_excepted,
            }))
          } else {
            resExcept = [...exceptedSections]
          }

          const res = await ClassesApi.getStudentCourseContent(id)
          setExplorerData(() => {
            const newData = res.data?.map((item: ICourseSection) => {
              const isActiveCourseContent = resExcept?.find(
                (course: ICourseSection) => course?.id === item?.id
              )
              return {
                ...item,
                checked: studentId
                  ? isActiveCourseContent
                    ? !isActiveCourseContent?.is_excepted
                    : false
                  : !resExcept.includes(item?.id),
              }
            })

            return [...TreeHelper.convertFromArray(newData, {convert_original: true})]
          })
        } catch (err) {
          console.error(err)
        } finally {
          setLoading(false)
        }
      }
    }

    async function fetchCourseDetail() {
      const params = {
        user_id: studentId,
      }
      const res = await ClassesApi.getCourseDetail({id: id, params: params})
      if (res) {
        let resProcess =
          res?.data?.course_sections_with_progress?.length > 0
            ? [...res?.data?.course_sections_with_progress]
            : []
        setExplorerData(() => {
          let newData = []
          for (let e of resProcess) {
            const percent = Math.round(
              (e?.learning_progress?.total_course_sections_completed /
                e.learning_progress.total_course_sections) *
                100
            )
            newData.push({...e, process: percent})
          }
          return [...TreeHelper.convertFromArray(newData, {convert_original: true})]
        })
      }
    }
    if (id) {
      type !== 'process' ? fetchCourseList() : fetchCourseDetail()
    } else {
      setExplorerData([])
    }
    setIsReset(false)
  }, [id, exceptedSections, studentId, classId, isReset])
  useEffect(() => {
    setCheckedAll(findNodeUnChecked(explorerData))
  }, [explorerData])

  return (
    <SappDrawer
      open={open}
      width='50%'
      title={title}
      handleSubmit={handleSubmit}
      handleClose={() => {
        setOpen({status: false, type: type, id: id, studentId: studentId})
      }}
      loading={loading}
      confirmOnclose={isLearning ? false : true}
      disabled={isEnded}
      classNameCancel={`fw-bold min-w-100px ${isLearning ? '' : 'me-5'}`}
      classNameSubmit={`${isLearning ? 'd-none' : ''}`}
      okButtonCaption={'Save'}
      cancelButtonCaption={`${isLearning ? 'Close' : 'Cancel'}`}
    >
      <>
        {type === 'course-content' && (
          <div className='d-flex align-items-center gap-3 mb-8'>
            <SAPPCheckbox
              checked={checkedAll}
              onChange={() => {
                checkAll(!checkedAll)
              }}
              disabled={isEnded}
            />
            <div className='sapp-text-truncate-1 sapp-text-primary fw-semibold fs-6'>
              Select All {explorerData?.length > 0 ? `(${explorerData?.length})` : ''}
            </div>
          </div>
        )}
        <div style={type === 'course-content' ? {marginLeft: '5px'} : {}}>
          {explorerData &&
            explorerData.map((item: any, index: number) => {
              return (
                <Accodian
                  explorer={item}
                  type={type}
                  action={handleChecked}
                  key={item.id}
                  isEnded={isEnded}
                />
              )
            })}
        </div>
      </>
    </SappDrawer>
  )
}
export default ModalProcessing
