import { Select } from 'antd'
import { UseFormReturn } from 'react-hook-form'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { BUTTON_TEXT } from 'src/constants/lang'
import HookFormSelectAntd from '../select/HookFormSelectAntd'
import { useState } from 'react'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'

type Props = {
  form: UseFormReturn<any>
  getImportLogs: ({
    text,
    status,
    page_index,
    page_size,
  }: {
    text?: string | undefined
    status?: string | undefined
    page_index: number
    page_size: number
  }) => Promise<void>
  meta?: {
    total_pages: number
    total_records: number
    page_index: number
    page_size: number
  }
  textSearch: string
  status: string
}

export enum JOB_STATUS {
  'PROCESSING' = 'PROCESSING',
  'FAILED' = 'FAILED',
  'SUCCESSFUL' = 'SUCCESSFUL',
  'PENDING' = 'PENDING',
}

const jobStatus = [
  {
    label: 'Processing',
    value: 'PROCESSING',
  },
  {
    label: 'Failed',
    value: 'FAILED',
  },
  {
    label: 'Successful',
    value: 'SUCCESSFUL',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
]

const FilterHeader = ({ form, meta, getImportLogs, status, textSearch }: Props) => {
  const { control, getValues, reset, setValue } = form
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    setLoading(true)
    setTimeout(() => {
      getImportLogs({
        page_index: 1,
        page_size: meta?.page_size ?? 10,
        text: getValues('text'),
        status: getValues('status'),
      }).finally(() => setLoading(false))
    })
  }

  const onReset = () => {
    setLoading(true)
    setTimeout(() => {
      reset()
      setValue('text', '')
      setValue('status', '')

      getImportLogs({
        page_index: 1,
        page_size: 10,
        text: '',
        status: '',
      }).finally(() => setLoading(false))
    })
  }

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-3 col-sm-4 ps-0 pe-xl-3 pe-md-3 pe-0'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
                <HookFormTextField
                  name='text'
                  control={control}
                  defaultValue={textSearch ?? ''}
                  placeholder='Search'
                  isListScreen
                  onSubmit={onSubmit}
                />
              </div>
            </div>
            <div className='col-xl-3 col-sm-4 sapp-height-min--content'>
              <HookFormSelectAntd
                size='large'
                name={'status'}
                control={control}
                dropdownStyle={{ minWidth: 'fit-content' }}
                placeholder={'Status'}
                showSearch
                defaultValue={status ?? ''}
                classNameHeight='sapp-h-40'
              >
                {jobStatus.map((option) => {
                  return (
                    <Select.Option
                      defaultValue={option.value}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </Select.Option>
                  )
                })}
              </HookFormSelectAntd>
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5'>
        <div className='d-flex'>
          <SAPPFIlterButton
            titleReset='Reset'
            titleSubmit={BUTTON_TEXT.SEARCH}
            okClick={onSubmit}
            resetClick={onReset}
            disabled={loading}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export default FilterHeader
