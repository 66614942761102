import {
  IQuestion,
  IQuestionCategory,
  IQuestionTag,
  IQuestionTagList,
  IResponseQuestionMeta,
  QUESTION_TYPES,
} from 'src/components/question-bank/shared/interfaces'
import {fetcher, getBaseUrl} from 'src/services/request'
import {IResponse} from 'src/type'
import {ITopic, ITopicList, ITopicObject} from 'src/type/question-bank'

export class QuestionBankAPI {
  static getTopic({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<ITopicList>> {
    return fetcher(`/question-topic?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }
  static getTopicById(id: string): Promise<IResponse<ITopicObject>> {
    return fetcher(`/question-topic/${id}`)
  }

  static createTopic(data: ITopic): Promise<IResponse<ITopicObject>> {
    return fetcher(`/question-topic`, {method: 'POST', data})
  }
  static editTopic(data: ITopic): Promise<IResponse<ITopicObject>> {
    return fetcher(`/question-topic/` + data.id, {method: 'PUT', data})
  }

  static deleteTopic = (id: string) => {
    return fetcher(`question-topic/${id}`, {
      method: 'DELETE',
    })
  }

  static getQuestions(
    {params}: {params: any},
    type: 'multiple' | 'constructed' | 'all'
  ): Promise<IResponseQuestionMeta<IQuestion[]>> {
    if (type === 'multiple') {
      params = {
        ...params,
        page_index: params?.page_index || 1,
        filter_type: params?.filter_type || 'ALL',
        qTypes: `${QUESTION_TYPES.DRAG_DROP},${QUESTION_TYPES.FILL_WORD},${QUESTION_TYPES.MATCHING},${QUESTION_TYPES.MULTIPLE_CHOICE},${QUESTION_TYPES.ONE_CHOICE},${QUESTION_TYPES.SELECT_WORD},${QUESTION_TYPES.TRUE_FALSE}`,
      }
    }
    if (type === 'constructed') {
      params = {
        ...params,
        page_index: params?.page_index || 1,
        filter_type: params?.filter_type || 'ALL',
        qTypes: `${QUESTION_TYPES.ESSAY}`,
      }
    }
    return fetcher('/question', {params})
  }

  static getQuestionById(id: string): Promise<IResponse<IQuestion>> {
    return fetcher(`/question/${id}`)
  }

  static createQuestion({data}: {data: IQuestion}): Promise<IResponse<IQuestion>> {
    return fetcher('/question', {method: 'POST', data: data})
  }

  static createSingleQuestion({data}: {data: IQuestion}): Promise<IResponse<IQuestion>> {
    return fetcher('/question/single', {method: 'POST', data: data})
  }

  static updateQuestion({id, data}: {id: string; data: IQuestion}): Promise<IResponse<IQuestion>> {
    return fetcher(`/question/${id}`, {method: 'PUT', data: data})
  }
  static deleteQuestion(id: string): Promise<IResponse<IQuestion>> {
    return fetcher(`/question/${id}`, {method: 'DELETE'})
  }
  static deleteBulkQuestion(ids: string[]): Promise<IResponse<IQuestion>> {
    return fetcher(`/question/bulk-delete`, {method: 'POST', data: {ids}})
  }

  static getQuizDetail(id: string): Promise<IResponse<any>> {
    return fetcher(`/quiz/${id}`, {method: 'GET'})
  }
  static updateQuizVideo({id, data}: any): Promise<IResponse<any>> {
    return fetcher(`/quiz/${id}`, {method: 'PUT', data})
  }

  static getBelongToQuiz(page_index: number, page_size: number, params?: Object): Promise<any> {
    return fetcher(`quiz/short/list?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getQuestionList(
    page_index: number,
    page_size: number,
    params?: Object
  ): Promise<IResponseQuestionMeta<IQuestion[]>> {
    return fetcher(`/question/list?page_index=${page_index}&page_size=${page_size}`, {params})
  }

  static getQuestionTags({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IQuestionTagList>> {
    return fetcher(`/question-tags?page_index=${page_index}&page_size=${page_size}`, {params})
  }
}
