import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import toast from 'react-hot-toast'
import {ClassesApi} from 'src/apis/classes'
import SappDrawer from 'src/components/base/SappDrawer'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import {RESOURCE_LOCATION} from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import {VALIDATION_FILED} from 'src/constants'
import {z} from 'zod'

interface IProps {
  open: boolean
  setOpen: any
  data?: any
  fetchClasses?: any
}
const BlockClass = ({open, setOpen, data, fetchClasses}: IProps) => {
  const validationSchema = z.object({
    reason: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
  })
  const {control, reset, handleSubmit} = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const handleClose = () => {
    reset()
    setOpen({status: false})
    fetchClasses()
  }
  const onSubmit = async (dataValue: any) => {
    try {
      const payload = {
        id: data,
        status: 'BLOCKED',
        reason: dataValue.reason,
      }
      await ClassesApi.updateClass(payload)
      toast.success('update classes status succeded')
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <SappDrawer
      open={open}
      title='Block Class'
      handleSubmit={handleSubmit(onSubmit)}
      width={'50%'}
      handleClose={handleClose}
      confirmOnclose
      cancelButtonCaption={'Cancel'}
      okButtonCaption={'Save'}
      footerClassName='border-0'
    >
      <HookFormEditor
        label='Reason'
        required
        name={'reason'}
        control={control}
        height={500}
        resourceLocation={RESOURCE_LOCATION.COURSE}
        object_id={undefined}
      />
    </SappDrawer>
  )
}
export default BlockClass
