import {fetcher, fetchFormData} from 'src/services/request'
import {IResponse} from 'src/type'
import {IStory, IStories, IStoryList} from 'src/type/story'

export class StoryAPI {
  static detail(
    id: string | undefined,
    page_index: number,
    page_size: number
  ): Promise<IResponse<any>> {
    return fetcher(`/story/${id}?page_index=${page_index}&page_size=${page_size}`)
  }

  static update(id: string, data: IStory): Promise<IResponse<IStories>> {
    return fetcher(`/story/${id}`, {method: 'PUT', data})
  }

  static getStory = (query: string) => {
    return fetcher(`/story?${query}`)
  }
  static getStoryById = (query: string, page_index: number, page_size: number, params?: Object) => {
    return fetcher(`/story/${query}?page_index=${page_index}&page_size=${page_size}`, {
      params,
    })
  }

  static createStories(data: IStory): Promise<IResponse<IStories>> {
    return fetcher(`/story`, {method: 'POST', data})
  }
  static deleteStory = async (id: string) => {
    return fetcher(`/story/${id}`, {
      method: 'DELETE',
    })
  }
  static updateTopics(id: string, data: any): Promise<IResponse<IStories>> {
    return fetcher(`/story/${id}/topics`, {method: 'PUT', data})
  }

  static getAPIStory = ({
    queryString,
    page_index,
    page_size,
  }: {
    queryString?: string
    page_index: number
    page_size: number
  }): Promise<IResponse<IStoryList>> => {
    if (queryString) {
      return fetcher(`/story?${queryString}`, {})
    } else
      return fetcher(`/story?page_index=${page_index}&page_size=${page_size}`, {
        // params: params,
      })
  }

  static updateOrderItemset = (id: string, topics: {topic_id: string; position: number}[]) => {
    return fetcher(`/story/${id}/topics/position`, {
      method: 'PUT',
      data: {topics},
    })
  }
}
