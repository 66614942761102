import React, {ReactNode, useState} from 'react'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import './MenuTabsSection.scss'
import {Skeleton} from 'antd'
import {useUserContext} from 'src/context/UserProvider'
import {Role} from 'src/type'
import {CODE_ADMIN, TITLE_QUIZ_GR} from 'src/constants/permission'

interface Tab {
  id: string
  title: string
  content: React.ReactNode
}

interface MenuTabsProps {
  tabs: Tab[]
  typeofSection?: any
  SectionName?: any
  confirm: (option: any) => void
  toggleLabelCallback: () => void
  onDeleteSection: (id: any) => void
  setEditAble: (editAble: any) => void
  index: number
  isEdit: boolean
  id?: any
  isLocked?: boolean
  isOnUsed: boolean
  setAddSection: any
  setCategorySate: any
  categoryState: any
  isQuestion?: boolean
  setPopup?: any
  section: any
  setValue: any
  setPreviousCategoryId?: any
  postionIndex?: number
  loading?: boolean
  cancelClick?: () => void
  hiddenDelete?: boolean
  children?: ReactNode
  deleteSection?: boolean | undefined
}

const MenuTabsSection: React.FC<MenuTabsProps> = ({
  typeofSection,
  tabs,
  confirm,
  toggleLabelCallback,
  onDeleteSection,
  setEditAble,
  isEdit,
  index,
  id,
  // isLocked,
  isOnUsed,
  setAddSection,
  setCategorySate,
  categoryState,
  setPreviousCategoryId,
  loading,
  // hiddenDelete = false,
  children,
  deleteSection,
}) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].id)
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false)
  const [isRotated, setIsRotated] = useState<boolean>(!id)
  const [expanded, setExpanded] = useState<boolean>(false)
  const changeTab = (tabId: string) => {
    setActiveTab(tabId)
  }
  const {profileMe} = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderEditCreateEntranceTest = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_QUIZ_GR.EDIT_QUIZ) ||
      hasPermission(role, TITLE_QUIZ_GR.CREATE_QUIZ) ||
      hasPermission(role, TITLE_QUIZ_GR.REMOVE_QUIZ) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const toggleSectionDisable = () => {
    if (((expanded && index !== id) || !expanded) && isAccordionOpen) {
      setEditAble(index)
      setCategorySate(categoryState)
      setPreviousCategoryId && setPreviousCategoryId(categoryState)
    }
    if (!isAccordionOpen) {
      toggleAccordion()
      setCategorySate(categoryState)
      setEditAble(index)
      setIsRotated(!isAccordionOpen)
      setPreviousCategoryId && setPreviousCategoryId(categoryState)
    }
  }
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen)
    setIsRotated(!isAccordionOpen)
    setExpanded(true)
    toggleLabelCallback()
  }

  return (
    <>
      <div
        className={`d-flex justify-content-between border sapp-px-24px sapp-h-62px ${
          isAccordionOpen || !id
            ? 'sapp-border-entrance-test'
            : 'sapp-pb-24px align-items-center sapp-pt-24px border-radius-6px'
        }`}
      >
        <div className='d-flex'>
          {isAccordionOpen || !id ? (
            tabs.map((tab) => (
              <div
                key={tab.id}
                className={`${
                  activeTab === tab.id
                    ? 'bg-blue-500 text-gray-800 fw-semibold'
                    : 'sapp-text--label fw-semibold hover:text-gray-600 dark:hover:text-gray-300'
                } focus:outline-none px-0 me-10 sapp-fs-14 sapp-lh-16px`}
                style={{
                  borderBottom: activeTab === tab.id ? '1px solid #FFB880' : 'none',
                  borderTop: '0',
                  borderLeft: '0',
                  borderRight: '0',
                  background: 'none',
                  fontWeight: 'bold',
                  marginTop: 22,
                  cursor: 'pointer',
                }}
                onClick={() => changeTab(tab.id)}
              >
                {tab.title}
              </div>
            ))
          ) : (
            <>
              {loading ? (
                <Skeleton.Button active />
              ) : (
                <div className='px-2 sapp-fs-14 fw-semibold text-gray-800 sapp-lh-14px'>
                  {typeofSection}
                </div>
              )}
            </>
          )}
        </div>
        <div className='d-flex gap-2 justify-content-center align-items-center'>
          {isEdit && !isOnUsed && (
            <div className='d-flex gap-2 justify-content-center'>
              <div>
                <ButtonIconOnly
                  disabled={!allowRenderEditCreateEntranceTest}
                  iconName={'notepad-edit'}
                  activeColor='primary'
                  bg='gray-100'
                  onClick={toggleSectionDisable}
                  fs='4'
                />
              </div>
              {id && !isOnUsed && deleteSection && (
                <div>
                  <ButtonIconOnly
                    bg='gray-100'
                    iconName={'trash'}
                    activeColor='danger'
                    disabled={!allowRenderEditCreateEntranceTest}
                    onClick={() => {
                      setTimeout(() => {
                        confirm({
                          okButtonCaption: 'Yes',
                          cancelButtonCaption: 'No',
                          body: 'Bạn có chắc chắn muốn xóa không?',
                          onClick: () => {
                            onDeleteSection(id)
                            setAddSection(true)
                            setEditAble(false)
                          },
                        })
                      })
                    }}
                    fs='4'
                  />
                </div>
              )}
            </div>
          )}
          {isEdit && (
            <div
              style={{
                padding: !isRotated ? 0 : '',
                transform: !isRotated ? 'rotate( 90deg)' : '',
                cursor: 'pointer',
              }}
            >
              <ButtonIconOnly
                iconName={'down'}
                activeColor='primary'
                bg='gray-100'
                onClick={toggleAccordion}
                fs='4'
              />
            </div>
          )}
        </div>
      </div>
      {(isAccordionOpen || !id) && (
        <div className='sapp-px-24px sapp-pt-24px sapp-pb-24px border'>
          {tabs.map((tab) => (
            <div key={tab.id} className={`${activeTab !== tab.id ? 'hidden' : 'block'}`}>
              {activeTab === tab.id && tab.content}
            </div>
          ))}
          {children}
        </div>
      )}
    </>
  )
}

export default MenuTabsSection
