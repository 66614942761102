import {IQuestion} from 'src/components/question-bank/shared/interfaces'
import {CourseSection} from './courses'
import {Abstract} from './abstract'

export class Quiz extends Abstract {
  name = ''
  description = ''
  quiz_question_mode = ''
  status = ''
  number_of_multiple_choice_questions = 0
  number_of_essay_questions = 0
  is_published = true
  is_public = true
  password = ''
  is_graded = true
  grading_method = ''
  multiple_choice_multiplier = 0
  essay_multiplier = 0
  required_percent_score = 0
  minimum_score = 0
  is_limited = true
  limit_count = 0
  is_tutor = true
  quiz_timed = ''
  setting = ''
  quiz_question_type = ''
  quiz_type = ''
  grading_preference = ''
  created_by = ''
  created_from = ''
  published_at = new Date()
  started_at = new Date()
  finished_at = new Date()
  course_category_id = ''
  subject = ''
  course_category = ''
  sections: any[] = []
  course_section = new CourseSection()
  constructed_questions: IQuestion[] = []
  multiple_choice_questions: IQuestion[] = []
  state: QuizState = new QuizState()

  constructor(entity: Partial<Quiz>) {
    super(entity)
    Object.assign(this, entity)
  }
}

export class QuizState {
  constructor() {
    this.condition = new QuizCondition()
    this.student_status = []
  }

  student_status: Array<string>
  condition: QuizCondition
}

export class QuizCondition {
  constructor() {
    this.can_edit_quiz = true
    this.can_delete_quiz = true
  }

  can_edit_quiz: boolean
  can_delete_quiz: boolean
}
