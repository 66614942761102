import { format } from 'date-fns'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { EyeIcon } from 'src/common/icons'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import ItemProfile from 'src/components/base/ItemProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { BREAKPOINT, FORMAT_DATETIME, MY_PROFILE, STUDENT_PROFILE } from 'src/constants'
import { LANG_PLACEHOLDER, LANG_USER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import useWindowWidth from 'src/hooks/use-width'
import { Role } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import '../StudentProfile.scss'
import StudentCMNDDetail from './StudentCMNDDetail'
import HeaderTab from 'src/components/base/HeaderTab'

interface IProps {
  studentDetail: IStudentDetail | undefined
  loading: boolean
  setStudentDetail: Dispatch<SetStateAction<any>>
}

const StudentProfileDetail = ({ studentDetail, loading, setStudentDetail }: IProps) => {
  const [open, setOpen] = useState(false)
  const { id } = useParams()
  const { profileMe } = useUserContext()
  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.EDIT_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderMakeDefault = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.MAKE_CONTACT_DEFAULT_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const { getStudentDetail, makeUseContactDefault } = useUserContext()

  const widthWindow = useWindowWidth()
  let identity_card_front = null
  let identity_card_back = null

  if (widthWindow > BREAKPOINT.xs) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['280x180']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['280x180']
  }

  if (widthWindow > BREAKPOINT.md) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['320x190']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['320x190']
  }

  if (widthWindow > BREAKPOINT.xl) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['370x220']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['370x220']
  }

  useEffect(() => {
    if (!id || loading) return

    getStudentDetail(id)
      .then((res) => {
        setStudentDetail(res?.data)
      })
      .catch((error) => {
        // if (error?.response?.data?.error?.code === '403|000000') {
        //   toast.error(DENIED_MESSAGES)
        // }
      })
  }, [id])

  /**
   * Sắp xếp mảng người dùng theo thời gian tạo và is_default.
   *
   * @param {Array} users - Mảng người dùng cần sắp xếp.
   * @returns {Array} - Mảng đã sắp xếp theo is_default và created_at.
   */
  const sortByCreatedAtAndDefault = (users: Array<any>) => {
    const sortedUsers = [...users]

    sortedUsers.sort((a, b) => {
      if (a.is_default && !b.is_default) return -1
      if (!a.is_default && b.is_default) return 1

      const dateA = new Date(a.created_at)
      const dateB = new Date(b.created_at)

      return dateB.getTime() - dateA.getTime()
    })

    return sortedUsers
  }

  return (
    <>
      {loading ? (
        <LoadingProfileDetail />
      ) : (
        <div>
          <div className='card mb-5 mb-xl-10'>
            <HeaderTab
              title='Overview'
              titleButton={studentDetail?.status !== 'MERGED' && allowRender ? 'Edit Profile' : ''}
              link={`${STUDENT_PROFILE}/${id}/${MY_PROFILE.SETTING}`}
            />
            <div className='card-body p-9'>
              <div className='row mb-7 align-items-center gy-1 g-xl-0'>
                <label className='col-lg-3 fw-semibold text-muted'>CMND/CCCD</label>
                <ButtonIcon
                  title={'View'}
                  customButton='sapp-student-eye col-lg-9 w-70px px-3 py-2 d-flex flex-row align-items-center justify-content-between'
                  onClick={() => setOpen(true)}
                >
                  <EyeIcon />
                </ButtonIcon>
              </div>
              <ItemProfile title='Student ID' body={studentDetail?.hubspot_contact_id} />
              {/* <ItemProfile title='Examination Code' body={studentDetail?.code} /> */}
              <ItemProfile
                title={LANG_PLACEHOLDER.FULLNAME}
                body={studentDetail?.detail?.full_name}
              />
              <ItemProfile title='Username' body={studentDetail?.username} />
              <ItemProfile title='Status' status={studentDetail?.status} />
              <ItemProfile title='Major' body={studentDetail?.detail?.major} />
              <ItemProfile title='University' body={studentDetail?.detail?.university} />
              <ItemProfile
                title='Gender'
                body={
                  studentDetail?.detail?.sex === 'MALE'
                    ? 'Male'
                    : studentDetail?.detail?.sex === 'FEMALE'
                    ? 'Female'
                    : studentDetail?.detail?.sex === 'OTHERS'
                    ? 'Others'
                    : '-'
                }
              />
              <ItemProfile
                title='Learning Purpose'
                body={studentDetail?.detail?.learning_purpose}
              />
              <ItemProfile title='Contact Detail' body={studentDetail?.detail?.contact_detail} />
              <ItemProfile title='Special Note' body={studentDetail?.detail?.special_note} />
              <ItemProfile title='Classification' body={studentDetail?.detail?.classification} />
              <ItemProfile title='Dob' body={studentDetail?.detail?.dob} />
              <ItemProfile title='Note' body={studentDetail?.detail?.note} />
              <ItemProfile
                title='ACCA current life cycle stage'
                body={studentDetail?.current_acca_lifecycle_stage}
              />
              <ItemProfile
                title='CFA current life cycle stage'
                body={studentDetail?.current_cfa_lifecycle_stage}
              />
              <ItemProfile
                title='CMA current life cycle stage'
                body={studentDetail?.current_cma_lifecycle_stage}
              />

              <ItemProfile
                title='Updated At'
                body={
                  studentDetail?.updated_at
                    ? format(new Date(studentDetail?.updated_at), FORMAT_DATETIME)
                    : ''
                }
              />
              <ItemProfile
                title={LANG_USER.TEST_ACCOUNT}
                body={studentDetail?.is_sapp_operator ? 'Yes' : 'No'}
              />
            </div>
          </div>

          {sortByCreatedAtAndDefault(studentDetail?.user_contacts || [])?.map((e, i) => {
            return (
              <div className='card mb-5 mb-xl-10' key={e.id}>
                <div className='card-header'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bold m-0 '>Profile {i + 1}</h3>
                    {e.is_default && (
                      <div className='badge badge-light-success justify-content-center min-w-55px ms-5'>
                        Default
                      </div>
                    )}
                  </div>

                  {!e.is_default && allowRenderMakeDefault && (
                    <ButtonSecondary
                      className='btn btn-sm btn-bg-light btn-active-color-primary align-self-center'
                      title={'Make This Default'}
                      onClick={async () => {
                        try {
                          await makeUseContactDefault({
                            userId: studentDetail?.id || '',
                            userContactId: e.id,
                          })
                          getStudentDetail(id).then((res) =>
                            setStudentDetail({
                              ...res?.data,
                              user_contacts: res?.data.user_contacts,
                            })
                          )
                          toast.success('Cập nhật Profile mặc định thành công!')
                        } catch (error) {}
                      }}
                    ></ButtonSecondary>
                  )}
                </div>

                <div className='card-body p-9'>
                  <div className='d-flex'>
                    <div className='sapp-flex-1 justify-content-between'>
                      {e.email && <ItemProfile title='Email' body={e.email} />}
                      {e.phone && <ItemProfile title='Phone Number' body={e.phone} />}
                    </div>
                    <div className=''></div>
                  </div>
                </div>
              </div>
            )
          })}
          <StudentCMNDDetail
            studentDetail={studentDetail}
            open={open}
            setOpen={setOpen}
            allowRender={allowRender}
            id={id}
            profileMe={profileMe}
          />
        </div>
      )}
    </>
  )
}

export default StudentProfileDetail
