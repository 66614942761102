import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import CourseDetail from 'src/components/courses/course-detail'
import { PageLink, STAFF_PROFILE, STUDENT_PROFILE, TEACHER_PROFILE } from 'src/constants'
import NotFound from 'src/pages/404/NotFound'
import ChangePassword from 'src/pages/change-password'
import Courses from 'src/pages/courses'
import CourseCategory from 'src/pages/courses/categories'
import Test from 'src/pages/courses/test'
import Dashboard from 'src/pages/dashboard'
import FogotPassword from 'src/pages/fogot-password'
import VerifyCode from 'src/pages/fogot-password/VerifyCode'
import ImportLog from 'src/pages/import-log/Index'
import ImportLogDetail from 'src/pages/import-log/detail'
import Login from 'src/pages/login'
import MyProfile from 'src/pages/my-profile'
import ListGroupReceiver from 'src/pages/group-receiver/ListGroupReceiver'
import ListNotification from 'src/pages/notifications/ListNotification'
import SendNotification from 'src/pages/notifications/SendNotification'
import Setting from 'src/pages/setting'
import StaffProfile from 'src/pages/staff-profile'
import CreateAndUpdateStaff from 'src/pages/staffs/CreateAndUpdateStaff'
import ListStaff from 'src/pages/staffs/ListStaff'
import StudentProfile from 'src/pages/student-profile'
import CreateAndUpdateStudent from 'src/pages/students/CreateAndUpdateStudent'
import ListStudents from 'src/pages/students'
import TeacherProfile from 'src/pages/teacher-profile'
import CreateAndUpdateTeacher from 'src/pages/teachers/CreateAndUpdateTeacher'
import Topics from 'src/pages/topics'
import Topic from 'src/pages/topics/Topic'
import ListTeachers from 'src/pages/teachers'
import ListStory from 'src/pages/story/ListStory'
import CreateStory from 'src/pages/story/CreateStory'
import CaseStudy from 'src/pages/courses/case-study/Index'
import Folders from 'src/pages/file-manager/Folders'
import PreviewCourseDetail from 'src/pages/preview-course-detail'
import Classes from 'src/pages/classes'
import CreateClassInfo from 'src/pages/classes/create/CreateClassInfo'
import ClassDetail from 'src/pages/classes/detail/ClassDetail'
import ImportLogStudents from 'src/pages/import-logs-class/ImportLogStudents'
import ImportStudentDetail from 'src/pages/import-logs-class/ImportStudentDetail'
import CertificatePage from 'src/pages/certificate'
import CertificatesTemplatePage from 'src/pages/certificate/CertificatesTemplatePage'
import EntranceTestList from 'src/pages/entrance-test'
/*import CreateGroup from "src/pages/group-receiver/CreateGroup";*/
import ParticipantList from 'src/pages/entrance-test/participantList'
import CreateAndUpdateGroup from 'src/pages/group-receiver/CreateAndUpdateGroup'
import RolesList from 'src/pages/roles'
import RoleDetail from 'src/pages/roles/detail/RoleDetail'
import QuizResultTable from 'src/pages/entrance-test/result/test-result/QuizResultTable'
import TestResult from 'src/pages/entrance-test/result/[id]'
import Explanation from 'src/pages/entrance-test/result/test-result/explanation'
import EntranceTestDetail from 'src/pages/entrance-test/EntranceTestDetail'
import QuestionList from 'src/pages/question-list'
import Question from 'src/pages/topics/Question'
import Video from 'src/pages/video'
import SyncHubspot from 'src/pages/sync-hubspot'
import CheckDuplicateContact from 'src/pages/check-duplicate-contact'
import ResultCheckDuplicateContact from 'src/pages/check-duplicate-contact/ResultImport'
import MailLog from 'src/pages/mail-log/Index'
import ParticipantEntranceTest from 'src/pages/entrance-test/participant-management/ParticipantEntranceTest'
import SupporterGroupList from 'src/pages/supporter-group/ListSupporterGroup'
import CreateUpdateSupporter from 'src/pages/supporter-group/CreateUpdateSupporter'
import EventTestList from 'src/pages/event-test'
import EventTestDetail from 'src/pages/event-test/event-test-detail'
import EventParticipantsList from 'src/pages/event-test/participant-list'
import ParticipantResult from 'src/pages/event-test/participant-list/results'
import ParticipantEventTest from 'src/pages/event-test/participant-list/detail'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to={PageLink.AUTH_LOGIN} />} />
        <Route path={PageLink.AUTH_FORGOTPASSWORD} element={<FogotPassword />} />
        <Route path={PageLink.ENTER_OTP} element={<VerifyCode />} />
        <Route path={PageLink.AUTH_CHAGEPASSWORD} element={<ChangePassword />} />
        <Route path={PageLink.AUTH_LOGIN} element={<Login />} />
        <Route path={`${PageLink.PROFILE}/*`} element={<MyProfile />} />
        <Route path={`${STAFF_PROFILE}/:id/*`} element={<StaffProfile />} />
        <Route path={`${STUDENT_PROFILE}/:id/*`} element={<StudentProfile />} />
        <Route path={`${TEACHER_PROFILE}/:id/*`} element={<TeacherProfile />} />
        <Route path={PageLink.ROLES} element={<RolesList />} />

        <Route path={`${PageLink.ROLE_DETAIL}/:id`} element={<RoleDetail />} />
        {/* <Route path={PageLink.PERMISSIONS} element={<Permissions />} /> */}
        <Route path={PageLink.CREATE_STAFF} element={<CreateAndUpdateStaff />} />
        <Route path={`${PageLink.UPDATE_STAFF}/:id`} element={<CreateAndUpdateStaff />} />
        <Route path={PageLink.STAFFS} element={<ListStaff />} />
        <Route path={`${PageLink.NOTIFICATIONS}/*`} element={<ListNotification />} />
        <Route path={`${PageLink.LIST_GROUP}/*`} element={<ListGroupReceiver />} />
        <Route path={PageLink.SEND_NOTIFICATIONS} element={<SendNotification />} />
        <Route path={`${PageLink.SEND_NOTIFICATIONS}/:id`} element={<SendNotification />} />
        <Route path={`${PageLink.STUDENTS}/*`} element={<ListStudents />} />
        <Route path={PageLink.CREATE_STUDENTS} element={<CreateAndUpdateStudent />} />
        <Route path={`${PageLink.UPDATE_STUDENT}/:id`} element={<CreateAndUpdateStudent />} />
        <Route path={`${PageLink.MAIL_LOGS}/*`} element={<MailLog />} />
        <Route path={`${PageLink.STUDENTS}/*`} element={<ListStudents />} />
        <Route path={PageLink.CREATE_STUDENTS} element={<CreateAndUpdateStudent />} />
        <Route path={`${PageLink.UPDATE_STUDENT}/:id`} element={<CreateAndUpdateStudent />} />
        <Route path={PageLink.DASHBOARD} element={<Dashboard />} />
        <Route path={PageLink.ENTRANCE_TEST_LIST} element={<EntranceTestList />} />
        <Route path={`${PageLink.ENTRANCE_EDIT_TEST_LIST}/:id`} element={<EntranceTestDetail />} />
        <Route path={`${PageLink.ENTRANCE_TEST_RESULT}/:id`} element={<TestResult />} />
        <Route path={`${PageLink.ENTRANCE_QUIZ_RESULT_TABLE}/:id`} element={<QuizResultTable />} />
        <Route path={`${PageLink.ENTRANCE_QUIZ_EXPLANATION}/:id`} element={<Explanation />} />
        <Route path={PageLink.PARTICIPANTS_LIST} element={<ParticipantList />} />
        <Route path={`${PageLink.COURSES}/*`} element={<Courses />} />
        <Route path={`${PageLink.CLASSES}/*`} element={<Classes />} />
        <Route path={`${PageLink.CREATE_CLASSES}`} element={<CreateClassInfo />} />
        <Route path={`${PageLink.CLASS_DETAIL}/:id/*`} element={<ClassDetail />} />
        <Route path={`${PageLink.COURSE_CATEGORY}/*`} element={<CourseCategory />} />
        <Route path={`${PageLink.TEST}/:type/:testFor/:courseId/:id`} element={<Test />} />
        <Route path={`${PageLink.TEST_EDIT}/:type/:courseId/:editId`} element={<Test />} />
        <Route path={`${PageLink.CASE_STUDY}/:courseId/:courseSectionId`} element={<CaseStudy />} />
        <Route
          path={`${PageLink.CASE_STUDY}/:courseId/:courseSectionId/:id`}
          element={<CaseStudy />}
        />
        {/* <Route path={`${PageLink.COURSE_FULL}/undefined`} element={<CreateAndUpdateCourseInfo />} /> */}
        {/* <Route path={`${PageLink.COURSE_FULL}/:id`} element={<CreateAndUpdateCourseInfo />} /> */}
        <Route path={PageLink.TOPICS} element={<Topics />} />
        <Route path={`${PageLink.TOPIC}/:id`} element={<Topic />} />
        <Route path={`${PageLink.TOPIC}`} element={<Topic />} />
        <Route path={`${PageLink.TOPIC}/:id${PageLink.QUESTION}/:id`} element={<Question />} />
        <Route path={PageLink.SETTING} element={<Setting />} />
        <Route path={PageLink.VIDEO} element={<Video />} />
        <Route path={PageLink.SYNC_HUBSPOT} element={<SyncHubspot />} />
        <Route path={PageLink.CHECK_DUPLICATE_CONTACT} element={<CheckDuplicateContact />} />
        <Route
          path={`${PageLink.CHECK_DUPLICATE_CONTACT_RESULT}/:id`}
          element={<ResultCheckDuplicateContact />}
        />
        <Route path={PageLink.TEACHERS} element={<ListTeachers />} />
        <Route path={PageLink.CREATE_TEACHERS} element={<CreateAndUpdateTeacher />} />
        <Route path={`${PageLink.UPDATE_TEACHERS}/:id`} element={<CreateAndUpdateTeacher />} />
        <Route path={`${PageLink.IMPORT_LOG}/:type`} element={<ImportLog />} />
        <Route path={`${PageLink.IMPORT_LOG}/detail/:type/:id`} element={<ImportLogDetail />} />
        <Route path={`${PageLink.COURSE_FULL}/:id`} element={<CourseDetail />} />
        <Route path={PageLink.STORY} element={<ListStory />} />
        <Route path={PageLink.CREATE_STORY} element={<CreateStory />} />
        <Route path={`${PageLink.STORY}/:id`} element={<CreateStory />} />
        <Route path={PageLink.FOLDER_LIST} element={<Folders />} />
        <Route path={`${PageLink.PREVIEW_PART}/:id`} element={<PreviewCourseDetail />} />
        <Route
          path={`${PageLink.PREVIEW_UNIT}/:id/unit/:unitId`}
          element={<PreviewCourseDetail />}
        />
        <Route path={PageLink.RESOURCE_MANAGER} element={<Folders />} />
        <Route path={PageLink.CERTIFICATES} element={<CertificatePage />} />
        <Route path={PageLink.CREATE_TEMPLATE} element={<CertificatesTemplatePage />} />
        <Route path={`${PageLink.CREATE_TEMPLATE}/:id`} element={<CertificatesTemplatePage />} />
        <Route path={`/logs/:type`} element={<ImportLogStudents />} />
        <Route path={`/logs/detail/:type/:id`} element={<ImportStudentDetail />} />
        <Route path={`${PageLink.CREATE_GROUP}`} element={<CreateAndUpdateGroup />} />
        <Route path={`${PageLink.UPDATE_GROUP}/:id`} element={<CreateAndUpdateGroup />} />
        <Route path={PageLink.QUESTION_LIST} element={<QuestionList />} />
        <Route
          path={`${PageLink.ENTRANCE_TEST}/:id${PageLink.PARTICIPANTS}`}
          element={<ParticipantEntranceTest />}
        />
        <Route path={PageLink.LIST_SUPPORTER_GROUP} element={<SupporterGroupList />} />
        <Route path={`${PageLink.CREATE_SUPORTER_GROUP}`} element={<CreateUpdateSupporter />} />
        <Route path={`${PageLink.LIST_SUPPORTER_GROUP}/:id`} element={<CreateUpdateSupporter />} />
        <Route path={PageLink.EVENT_TEST} element={<EventTestList />} />
        <Route path={`${PageLink.CREATE_EVENT_TEST}/:id`} element={<EventTestDetail />} />
        <Route path={`${PageLink.EVENT_PARTICIPANT_LIST}/*`} element={<EventParticipantsList />} />
        <Route
          path={`${PageLink.EVENT_PARTICIPANT_LIST}/result/:id`}
          element={<ParticipantResult />}
        />
        <Route
          path={`${PageLink.EVENT_TEST}/:id${PageLink.PARTICIPANTS}`}
          element={<ParticipantEventTest />}
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
