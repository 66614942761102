import React from 'react'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'

interface IProps {
  titleReset: string
  resetClick: () => void
  titleSubmit: string
  okClick: () => void
  disabled: boolean
  loading?: boolean
  classNameSubmit?: string
  classNameCancel?: string
}

const SappFilterButton = ({
  okClick,
  resetClick,
  titleReset,
  titleSubmit,
  disabled,
  loading,
  classNameSubmit,
  classNameCancel,
}: IProps) => {
  return (
    <div className='d-flex'>
      <div className=''>
        <ButtonSecondary
          title={titleReset}
          className={`${classNameCancel ?? ''} me-4`}
          onClick={resetClick}
          disabled={disabled}
          isListScreen
        />
      </div>
      <div className='w-100'>
        <ButtonPrimary
          title={titleSubmit}
          className={`${classNameSubmit ?? ''}`}
          onClick={okClick}
          disabled={disabled}
          loading={loading}
          isListScreen
        />
      </div>
    </div>
  )
}

export default SappFilterButton
