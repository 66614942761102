import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'src/_metronic/assets/keenicons/duotone/style.css'
import 'src/_metronic/assets/keenicons/outline/style.css'
import 'src/_metronic/assets/keenicons/solid/style.css'
import 'src/_metronic/assets/sass/style.scss'
import 'src/_metronic/assets/sass/plugins.scss'
import 'src/styles/index.scss'
import App from './App'
import { UserProvider } from './context/UserProvider'
import { Toaster } from 'react-hot-toast'
import { CourseProvider } from './context/CourseProvider'
import { LoadingProvider } from './context/LoadingContext'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <CourseProvider>
          <LoadingProvider>
            <App />
          </LoadingProvider>
          <Toaster toastOptions={{ className: 'sapp-mw-1000px' }} />
        </CourseProvider>
      </UserProvider>
    </QueryClientProvider>
  )
}
