import React, {Dispatch, SetStateAction} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {IClassDetail} from 'src/type/classes'
import ClassProfileDetail from './ClassProfileDetail'
import {CLASS_PROFILE_URL} from 'src/constants/classes'
import SettingClassUser from './setting/SettingClassUser'
import ListStudent from './user/ListStudent'
import ListMentorClass from './user/ListMentorClass'
import ListTeacher from './user/ListTeacher'

interface IProps {
  classDetail: IClassDetail | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setClassDetail: Dispatch<SetStateAction<any>>
}

const RenderClassProfile = ({classDetail, setLoading, loading, setClassDetail}: IProps) => {
  const location = useLocation()
  const {id} = useParams()

  const renderClass = () => {
    switch (location.pathname.split(`/${id}/`)[1]) {
      case CLASS_PROFILE_URL.OVERVIEW:
        return (
          <ClassProfileDetail
            classDetail={classDetail}
            loading={loading}
            setClassDetail={setClassDetail}
          />
        )
      case CLASS_PROFILE_URL.SETTING:
        return (
          <SettingClassUser
            classDetail={classDetail}
            setClassDetail={setClassDetail}
            loading={loading}
            setLoading={setLoading}
          />
        )
      case CLASS_PROFILE_URL.STUDENTS:
        return (
          <ListStudent
            classDetail={classDetail}
            setClassDetail={setClassDetail}
            loading={loading}
            setLoading={setLoading}
          />
        )
      case CLASS_PROFILE_URL.MENTORS:
        return (
          <ListMentorClass classDetail={classDetail} loading={loading} setLoading={setLoading} />
        )
      case CLASS_PROFILE_URL.TEACHERS:
        return (
          <ListTeacher
            classDetail={classDetail}
            setClassDetail={setClassDetail}
            loading={loading}
            setLoading={setLoading}
          />
        )
      case CLASS_PROFILE_URL.EXAMINATION:
        return <div>examination</div>
      case CLASS_PROFILE_URL.MARK:
        return <div>mark</div>
      default:
        return (
          <ClassProfileDetail
            classDetail={classDetail}
            loading={loading}
            setClassDetail={setClassDetail}
          />
        )
    }
  }
  return <>{classDetail && renderClass()}</>
}

export default RenderClassProfile
