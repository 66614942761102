import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { ITabs, Role } from 'src/type'
import withAuthComponents from 'src/components/auth/with-auth-components'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { KTIcon } from 'src/_metronic/helpers'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import { SyncHubspotAPI } from 'src/apis/sync-hubspot'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_ADMIN_GR } from 'src/constants/permission'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.SYNC_HUBSPOT}`,
    title: 'Sync Hubspot Management',
  },
]

const SyncHubspot = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [countLearnTrialTicketFail, setCountLearnTrialTicketFail] = useState<number>(0)
  const [countEntranceTestTicketFail, setCountEntranceTestTicketFail] = useState<number>(0)
  const [isProcessingLearnTrialTicketFail, setIsProcessingLearnTrialTicketFail] = useState(false)
  const [isProcessingEntranceTestTicketFail, seProcessingtEntranceTestTicketFail] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const resLearnTrial = await SyncHubspotAPI.countLearnTrialTicketFail()
        const resEntranceTest = await SyncHubspotAPI.countEntranceTestTicketFail()
        setCountLearnTrialTicketFail(resLearnTrial.data || 0)
        setCountEntranceTestTicketFail(resEntranceTest.data || 0)
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  const reprocessLeanTrialTicketFail = async () => {
    try {
      setIsProcessingLearnTrialTicketFail(true)
      const res = await SyncHubspotAPI.reprocessLeanTrialTicketFail()
      if (res.data) {
        setCountLearnTrialTicketFail(countLearnTrialTicketFail - res.data)
        toast.success(`Reprocessed ${res.data} learn trial ticket fail`)
      } else {
        toast.error('No data to be processed')
      }
      setIsProcessingLearnTrialTicketFail(false)
    } catch (error) {}
  }

  const reprocessEntranceTestTicketFail = async () => {
    try {
      seProcessingtEntranceTestTicketFail(true)
      const res = await SyncHubspotAPI.reprocessEntranceTestTicketFail()
      if (res.data) {
        setCountEntranceTestTicketFail(countEntranceTestTicketFail - res.data)
        toast.success(`Reprocessed ${res.data} entrance test ticket fail`)
      } else {
        toast.error('No data to be processed')
      }
      seProcessingtEntranceTestTicketFail(false)
    } catch (error) {}
  }

  // * Check phân quyền
  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderReprocess = hasPermission(
    profileMe?.roles,
    TITLE_ADMIN_GR.REPROCESS_LEARN_TRIAL_TICKET_FAIL ||
      TITLE_ADMIN_GR.REPROCESS_ENTRANCE_TEST_TICKET_FAIL
  )

  return (
    <PageLayouts pageTitle='Sync Hubspot Management' breadcrumbs={breadcrumbs}>
      {allowRenderReprocess && (
        <div className='row row-cols-1 row-cols-md-1 pt-10'>
          <div className='sapp-table-min-height'>
            <div className='card h-md-30 mb-5'>
              <div className='card-body d-flex justify-content-between flex-center'>
                <div>
                  <h1>
                    Reprocess Learn Trial Ticket Fail (
                    {isLoading ? (
                      <span className='spinner-border spinner-border-sm align-middle'></span>
                    ) : (
                      <span className='text-danger'>{countLearnTrialTicketFail}</span>
                    )}
                    )
                  </h1>
                  <p>
                    Chức năng dùng để xử lý lại những ticket đăng ký học thử không được xử lý (stage
                    đang ở Khởi tạo tài khoản) trên 1 tiếng <br></br>
                    Khi thực hiện sẽ lấy ra tất cả những ticket trên để xử lý tạo tài khoản học thử
                    cho học viên
                  </p>
                </div>
                <div>
                  <ButtonIcon
                    loading={isProcessingLearnTrialTicketFail}
                    title={'Reprocess'}
                    onClick={reprocessLeanTrialTicketFail}
                  >
                    <KTIcon iconName='arrows-circle' className='fs-3 pe-3' iconType='solid' />
                  </ButtonIcon>
                </div>
              </div>
            </div>
            <div className='card h-md-30 mb-5'>
              <div className='card-body d-flex justify-content-between flex-center'>
                <div>
                  <h1>
                    Reprocess Entrance Test Ticket Fail (
                    {isLoading ? (
                      <span className='spinner-border spinner-border-sm align-middle'></span>
                    ) : (
                      <span className='text-danger'>{countEntranceTestTicketFail}</span>
                    )}
                    )
                  </h1>
                  <p>
                    Chức năng dùng để xử lý lại những ticket đăng ký thi thử không được xử lý (stage
                    đang ở Khởi tạo tài khoản) trên 1 tiếng <br></br>
                    Khi thực hiện sẽ lấy ra tất cả những ticket trên để xử lý tạo tài khoản thi đầu
                    vào cho học viên
                  </p>
                </div>
                <div>
                  <ButtonIcon
                    loading={isProcessingEntranceTestTicketFail}
                    title={'Reprocess'}
                    onClick={reprocessEntranceTestTicketFail}
                  >
                    <KTIcon iconName='arrows-circle' className='fs-3 pe-3' iconType='solid' />
                  </ButtonIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </PageLayouts>
  )
}

export default withAuthComponents(SyncHubspot)
