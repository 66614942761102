import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { HEADER_ENTRANCE_TEST_LIST } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import {
  capitalizeFirstLetter,
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { Link } from 'react-router-dom'
import ModalProcessing from 'src/components/classes/modalProcessing'
import EntranceAction from 'src/components/entrance-test/EntranceTestAction'
import { PageLink } from 'src/constants'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import { toast } from 'react-hot-toast'
import { EntranceApi } from 'src/apis/entrance-test'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import {
  OPTIONS_OF_STATUS_ACTIVE,
  OPTIONS_OF_STATUS_DEACTIVE,
  OPTIONS_OF_STATUS_DRAFT,
} from 'src/constants/entrancetest'
import { Select } from 'antd'
import { useUserContext } from 'src/context/UserProvider'
import { Role } from 'src/type'
import { CODE_ADMIN, TITLE_QUIZ_GR } from 'src/constants/permission'
import AddParticipant from 'src/components/entrance-test/participant/AddParticipant'
import { PROGRAM } from 'src/type/courses'

const { Option } = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  EntranceTestList: any
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchEntranceTestList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

// define headers
const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Test name',
    className: 'min-w-175px pe-5',
  },
  {
    label: 'Program',
    className: 'min-w-50px pe-5',
  },
  {
    label: 'Subject',
    className: 'min-w-50px pe-5',
  },
  {
    label: 'Date',
    className: 'min-w-50px pe-5',
  },
  {
    label: 'Participant',
    className: 'max-w-50px pe-5',
  },
  {
    label: 'Status',
    className: 'max-w-50px pe-5',
  },
]

const TableTest = ({
  EntranceTestList,
  loading,
  setLoading,
  filterParams,
  fetchEntranceTestList,
  getParams,
  queryParams,
  isCheckedAll,
  toggleCheckAll,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const [openParticipant, setOpenParticipant] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string>('')
  const navigate = useNavigate()
  // const [openBlock, setOpenBlock] = useState<{status: boolean; data?: any}>({status: false})
  const [openEditCourseContent, setOpenEditCourseContent] = useState<{
    status: boolean
    type?: string
    data?: any
  }>({ status: false })
  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)
  const showQueryParams =
    queryParams.name ||
    queryParams.course_category_id ||
    queryParams.subject ||
    queryParams.sortType ||
    queryParams.fromDate ||
    queryParams.toDate ||
    queryParams.status ||
    queryParams.instruction_mode ||
    queryParams.quizType
  // TODO: dùng để filter params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.course_category_id ?? '',
      queryParams.subject ?? '',
      queryParams.sortType ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      queryParams.status ?? '',
      queryParams.quizType ?? 'ENTRANCE_TEST',
      queryParams.dateField ?? 'updated_at',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )
  // Using validate for input
  const { control, watch, setValue } = useForm<any>({
    mode: 'onChange',
  })

  useEffect(() => {
    setLoading(true)
    fetchEntranceTestList(page_index || 1, page_size || 10, filterParams)
    handleChangeParams(page_index || 1, page_size || 10)
  }, [])

  useEffect(() => {
    EntranceTestList?.quizzes
      ?.map((e: any) => ({ label: e.status, value: e.status }))
      ?.forEach((option: any, index: any) => {
        setValue(`status${index}`, capitalizeFirstLetter(option.value))
      })
  }, [setValue, EntranceTestList?.quizzes])

  const handleChangeStatus = (value: unknown, data: any, index: number) => {
    const request: any = {
      name: data?.name,
      status: value,
      courseCategoryId: data?.courseCategoryId,
    }

    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: async () => {
        try {
          await EntranceApi.editStatusEntranceTest({ ...request }, data?.id)
          toast.success('Update Status Successfully!')
          fetchEntranceTestList(page_index || 1, page_size || 10, filterParams)
        } catch (error: any) {
          setValue(`status${index}`, data?.status)
          // if (error?.response?.data?.error?.code === '400|060005') {
          //   toast.error(`Permission denied!`)
          // }
          // if (error?.response?.data?.error?.code === '403|000000') {
          //   toast.error(DENIED_PERMISSIONS)
          // }
          setValue(`status${index}`, data?.status?.toUpperCase())
          fetchEntranceTestList(page_index || 1, page_size || 10, filterParams)
        } finally {
          setLoading(false)
        }
      },
      onClose: () => {
        fetchEntranceTestList(page_index || 1, page_size || 10, filterParams)
      },
    })
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      name: queryParams.name ?? '',
      course_category_id: queryParams?.course_category_id ?? '',
      subject: queryParams.subject ?? '',
      sortType: queryParams.sortType ?? '',
      fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      toDate: formatDate(queryParams.toDate as Date) ?? '',
      status: queryParams.status ?? '',
      page_index: currenPage,
      page_size: size,
      quizType: 'ENTRANCE_TEST',
      dateField: 'updated_at',
    }
    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchEntranceTestList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  const Action = (type: string, data: any) => {
    if (type === 'Edit') {
      navigate(`${PageLink.ENTRANCE_EDIT_TEST_LIST}/${data.id}`)
    }
    if (type === 'add-participant') {
      setOpenParticipant(true)
      setSelectedId(data.id)
    }
    if (type === 'participant-list') {
      navigate(`/entrance-test/${data?.id}/participants`, { replace: true })
    }
  }

  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_QUIZ_GR.EDIT_QUIZ)

  return (
    <div className='card-body py-4'>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={EntranceTestList?.quizzes}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        hasCheck={false}
      >
        {loading ? (
          <>
            {HEADER_ENTRANCE_TEST_LIST.map((header, i) => (
              <LoadingTable key={header} headers={HEADER_ENTRANCE_TEST_LIST} />
            ))}
          </>
        ) : (
          <>
            {EntranceTestList?.quizzes?.map((e: any, index: number) => {
              let showStatus: Array<any>
              switch (e.status) {
                case 'DRAFT':
                  showStatus = OPTIONS_OF_STATUS_ACTIVE
                  break
                case 'ACTIVE':
                  showStatus = OPTIONS_OF_STATUS_DRAFT
                  break
                case 'LOCKED':
                  showStatus = OPTIONS_OF_STATUS_DEACTIVE
                  break
                default:
                  showStatus = []
              }
              return (
                <tr key={e.id}>
                  <td>
                    {index +
                      1 +
                      (EntranceTestList?.meta?.page_index - 1) * EntranceTestList?.meta?.page_size}
                  </td>
                  <td className='text-start'>
                    <Link
                      className='text-gray-800 sapp-text-truncate-1 text-hover-primary'
                      to={`${PageLink.ENTRANCE_EDIT_TEST_LIST}/${e.id}`}
                    >
                      {e?.name}
                    </Link>
                  </td>
                  <td className='text-start'>{e?.course_category?.name}</td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-1'>
                      {e?.course_category?.name === PROGRAM.CFA ? null : e?.subject?.name}
                    </div>
                  </td>
                  <td className='text-start'>
                    <DatetimeColumn created_at={e.created_at} updated_at={e.updated_at} />
                  </td>
                  <td className='text-start'>
                    <div>{e?.quiz_user_instances?.length ?? 0}</div>
                  </td>
                  <td className='text-start'>
                    <HookFormSelectAntd
                      size='large'
                      name={`status${index}`}
                      control={control}
                      dropdownStyle={{ maxWidth: 100 }}
                      placeholder='Status'
                      filterOption={true}
                      disabled={!allowRenderEdit || e.status === 'DEACTIVE'}
                      onChange={(selectedValue: unknown) =>
                        handleChangeStatus(selectedValue, e, index)
                      }
                      defaultValue={e?.status ?? ''}
                      classNameHeight='sapp-h-35px'
                      isLocked={e.status === 'DEACTIVE'}
                    >
                      {showStatus?.map((status) => (
                        <Option key={status.value} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </td>
                  <td className='text-end absolute bg-white absolute-column'>
                    <EntranceAction Action={Action} data={e} />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <ModalProcessing
        open={openEditCourseContent.status}
        setOpen={setOpenEditCourseContent}
        type={openEditCourseContent.type}
        title={
          openEditCourseContent.type === 'process' ? 'Learning Process' : 'Edit Course Content'
        }
        id={openEditCourseContent?.data?.course_id}
        exceptedSections={openEditCourseContent?.data?.excepted_course_section || []}
        classId={openEditCourseContent?.data?.id}
        refetch={() =>
          fetchEntranceTestList(page_index || 1, page_size || 10, filterParamsPagination)
        }
      />
      <AddParticipant id={selectedId} open={openParticipant} setOpen={setOpenParticipant} />

      <PagiantionSAPP
        currentPage={EntranceTestList?.meta?.page_index || 1}
        pageSize={EntranceTestList?.meta?.page_size || 10}
        totalItems={EntranceTestList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default TableTest
