import {Dispatch, SetStateAction, useState} from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import useChecked from 'src/hooks/use-checked'
import {useConfirm} from 'src/hooks/use-confirm'
import {ClassesApi} from 'src/apis/classes'
import TableMentor from './TableMentor'
import {IMentor, IMentorClass, IMentorList} from 'src/type/mentors'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  id: string
  getClassMentor: () => void
}
const AddMentor = ({open, setOpen, id, getClassMentor}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [userState, setUserState] = useState<IMentorList>()
  const getParams = (
    text: string,
    sortType: string,
    fromDate?: Date | string,
    toDate?: Date | string,
    dateField?: string
  ) => ({
    text,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })
  const queryParams = {
    text: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }
  const fetchListUsers = async (
    page_index?: number,
    page_size?: number,
    params?: {text?: string; fromDate?: Date; toDate?: Date; sortType?: string; to_add?: boolean}
  ) => {
    try {
      const option = {...params, to_add: true}
      const res = await ClassesApi.getMentors({
        class_id: id,
        page_index: page_index || 1,
        page_size: page_size || 10,
        params: option,
      })
      setUserState(res.data)
    } catch (err) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  const {checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll} =
    useChecked<IMentor>(userState?.data ?? undefined)

  const {confirm, contextHolder} = useConfirm()

  const addMentorToClass = async () => {
    try {
      setLoading(true)
      const res = await ClassesApi.detail(id)
      const existed = res?.data?.class_mentors?.map((item: IMentorClass) => ({
        mentor_id: item?.mentor?.id,
        is_main_mentor: item?.is_main_mentor,
      }))
      const isMain = existed.find((item: IMentorClass) => item.is_main_mentor === true)
      const mentors = userState?.data
        .filter((staff: IMentor) => checkedList.includes(staff.id))
        .map((item: IMentor, idx: number) => ({
          mentor_id: item.id,
          is_main_mentor: isMain ? false : idx === 0,
        }))
        .concat(existed)
      await ClassesApi.updateClass({mentors, id})
      getClassMentor()
    } catch (err) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
    toggleCheckAll(false)
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: 'Bạn có chắc chắn muốn hủy không?',
      onClick: handleClose,
    })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      setOpen(false)
      await addMentorToClass()
      toggleCheckAll(false)
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Add Mentor</div>
          <div className='d-flex justify-content-between align-items-center'>
            {listDataChecked.length > 0 && (
              <div className='fw-bold fs-6 text-primary me-8'>
                {listDataChecked.length} Selected
              </div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0'
              classNameSubmit='fw-bold me-5'
              loading={loading}
            />
          </div>
        </div>
        <div className='px-7'>
          <TableMentor
            userState={userState}
            loading={loading}
            setLoading={setLoading}
            handleChangeParams={() => {}}
            fetchListUsers={fetchListUsers}
            filterParams={{}}
            getParams={getParams}
            queryParams={queryParams}
            checkedList={checkedList}
            toggleCheck={toggleCheck}
            toggleCheckAll={toggleCheckAll}
            isCheckedAll={isCheckedAll}
          />
        </div>
      </SappModal>
    </>
  )
}
export default AddMentor
