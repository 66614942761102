import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {toast} from 'react-hot-toast'
import {Link, useLocation} from 'react-router-dom'
import {QuestionBankAPI} from 'src/apis/question-bank'
import PopupConfirm from 'src/common/PopupConfirm'
import {PageLink} from 'src/constants'
import {MenuComponent} from 'src/_metronic/assets/ts/components'
import ActionCell from '../base/action/ActionCell'
import {useUserContext} from 'src/context/UserProvider'
import {CODE_ADMIN, TITLE_TOPIC_GR} from 'src/constants/permission'
import {Role} from 'src/type'

interface IProps {
  setTopicList: Dispatch<SetStateAction<any>>
  id: string
  cleanedParams: Object
  setLoading: Dispatch<SetStateAction<boolean>>
  pageSize: number
  currentPage: number
}

export const QuestionAction = ({
  id,
  setTopicList,
  cleanedParams,
  setLoading,
  pageSize,
  currentPage,
}: IProps) => {
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const location = useLocation()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const {profileMe} = useUserContext()
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderDelete = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const deleteItemTopic = async () => {
    setLoading(true)
    try {
      const res = await QuestionBankAPI.deleteTopic(id)
      if (res?.success) {
        setOpenModalDelete(true)

        QuestionBankAPI.getTopic({
          page_index: currentPage,
          page_size: pageSize,
          params: cleanedParams,
        }).then((res) => {
          setTopicList(res?.data)
          setLoading(false)
        })
      }
    } catch (error: any) {
      const res = error?.response?.data?.error?.code
      if (res === '400|030022' || res === '400|030208') {
        toast.error('Test/Quiz/Case Study included the question(s).')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <ActionCell customWidth='w-150px'>
        {/* begin::Menu item */}
        {allowRenderEdit && (
          <Link to={`${PageLink.TOPIC}/${id}${location.search || ''}`} className='menu-item px-3'>
            <div className='menu-link px-3'>Edit</div>
          </Link>
        )}
        {/* end::Menu item */}
        {allowRenderDelete && (
          <div className='menu-item px-3' onClick={() => setOpenModalDelete(true)}>
            <div className='menu-link px-3'>Delete</div>
          </div>
        )}

        {/* end::Menu item */}
      </ActionCell>
      <PopupConfirm
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClick={deleteItemTopic}
        body='Bạn có chắc chắn muốn xóa không?'
      />
    </>
  )
}
