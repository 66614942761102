import React from 'react'
import {Link} from 'react-router-dom'
import {default as chooseFinalTestHovered} from 'src/_metronic/assets/images/mock-test/choose-final-test-d.svg'
import {default as chooseFinalTest} from 'src/_metronic/assets/images/mock-test/choose-final-test.svg'
import {default as chooseMidtermTestHovered} from 'src/_metronic/assets/images/mock-test/choose-midterm-test-d.svg'
import {default as chooseMidtermTest} from 'src/_metronic/assets/images/mock-test/choose-midterm-test.svg'
import SappModal from 'src/components/base/SappModal'
import {PageLink} from 'src/constants'
import {useCourseContext} from 'src/context/CourseProvider'
import {TEST_TYPE} from '../shared/Interfaces'
import './ChooseTypeOfTestModal.scss'

type Props = {
  open: {
    type: string
    open: boolean
    id?: string
  }
  setOpen: React.Dispatch<
    React.SetStateAction<{
      type: string
      open: boolean
      id?: string
    }>
  >
}

export const COURSE_TEST_TYPES = {
  COURSE: 'COURSE',
  PART: 'PART',
  CHAPTER: 'CHAPTER',
}

const DEFAULT_WIDTH = 128
const DEFAULT_HEIGHT = 93

const ChooseTypeOfTestModal = ({open, setOpen}: Props) => {
  const {idCourse} = useCourseContext()

  const handleClose = () => {
    setOpen({
      type: COURSE_TEST_TYPES.COURSE,
      open: false,
    })
  }

  return (
    <SappModal
      centered
      dialogClassName='sapp-mw-520px'
      open={open.open}
      title='Create Test'
      handleClose={handleClose}
      showFooter={false}
      classBody='sapp-px-84px py-12 modal-body'
    >
      {/* start:: body modal */}
      <div className='d-md-flex justify-content-xl-between justify-content-md-evenly'>
        <Link
          to={`${
            PageLink.TEST
          }/${TEST_TYPE.MID_TERM_TEST.toLocaleLowerCase()}/${open?.type?.toLowerCase()}/${idCourse}/${
            open.id || idCourse
          }`}
          className='mock-test-type_content d-block'
          role='button'
        >
          <img
            className='mock-test-type_img'
            src={chooseMidtermTest}
            alt='Choose Midterm Test'
            width={DEFAULT_WIDTH}
            height={DEFAULT_HEIGHT}
          />
          <img
            className='mock-test-type_img__hovered'
            src={chooseMidtermTestHovered}
            alt='Choose Midterm Test'
            width={DEFAULT_WIDTH}
            height={DEFAULT_HEIGHT}
          />
          <p className='mock-test-type_name mt-4 mb-0 text-center fw-semibold'>Midterm Test</p>
        </Link>
        <Link
          to={`${
            PageLink.TEST
          }/${TEST_TYPE.FINAL_TEST.toLocaleLowerCase()}/${open?.type?.toLowerCase()}/${idCourse}/${
            open.id || idCourse
          }`}
          className='mock-test-type_content d-block'
          role='button'
        >
          <img
            className='mock-test-type_img'
            src={chooseFinalTest}
            alt='Choose Final Test'
            width={DEFAULT_WIDTH}
            height={DEFAULT_HEIGHT}
          />
          <img
            className='mock-test-type_img__hovered'
            src={chooseFinalTestHovered}
            alt='Choose Final Test'
            width={DEFAULT_WIDTH}
            height={DEFAULT_HEIGHT}
          />
          <p className='mock-test-type_name mt-4 mb-0 text-center fw-semibold'>Final Test</p>
        </Link>
      </div>
      {/* end:: body modal */}
    </SappModal>
  )
}

export default ChooseTypeOfTestModal
