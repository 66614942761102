import React from 'react'
import {KTIcon} from 'src/_metronic/helpers'
import ButtonIcon from '../button/ButtonIcon'

interface IProps {
  title: string
  onClick: () => void
  className?: string
  loading: boolean
  disabled?: boolean
  isListScreen?: boolean | undefined
}

const ExportExcel = ({title, onClick, className, loading, disabled, isListScreen}: IProps) => {
  return (
    <ButtonIcon
      customButton='btn-sapp-filter'
      title={title}
      className={`${className ?? ''} me-3 w-100 btn btn-primary`}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      isListScreen={isListScreen}
    >
      <KTIcon iconName='file-down' className='fs-3 pe-3' iconType='outline' />
    </ButtonIcon>
  )
}

export default ExportExcel
