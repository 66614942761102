import React, {Dispatch, SetStateAction, useState} from 'react'
import sapp from 'src/_metronic/assets/media/logos/sapp.png'
import SappBreadCrumbs from 'src/components/base/breadcrumbs/SappBreadCrumbs'
import {ITabs} from 'src/type'
import {Drawer} from 'antd'
import Sidebar from 'src/components/layout/sidebar'

interface IProps {
  pageTitle: string | undefined
  breadcrumbs: ITabs[] | undefined
  setOpenNotifications: Dispatch<SetStateAction<boolean>>
}

const Header = ({pageTitle, breadcrumbs, setOpenNotifications}: IProps) => {
  const [open, setOpen] = useState(false)

  // function open show drawer
  const showDrawer = () => {
    setOpen(true)
  }

  // function close show drawer
  const onClose = () => {
    setOpen(false)
  }

  return (
    <div className='app-header sapp-custom-background-layout'>
      <div className='app-container container-xxl d-flex align-items-stretch flex-stack'>
        <div className='d-flex align-items-center d-lg-none'>
          {/* start:: button drawer */}
          <button
            className='btn btn-icon btn-color-gray-500 btn-active-color-primary ms-n3 me-1'
            onClick={showDrawer}
          >
            <i className='ki-outline ki-burger-menu-6 fs-2x'></i>
          </button>
          {/* end:: button show drawer */}

          {/* start:: show drawer */}
          <Drawer placement={'left'} closable={false} onClose={onClose} open={open} width={320}>
            <div className='d-flex h-100 sapp-bg-sidebar'>
              <Sidebar />
            </div>
          </Drawer>
          {/* end:: show drawer */}

          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <a href='/'>
              <img alt='Logo' src={sapp} className='h-30px sapp-w-60px' />
            </a>
          </div>
        </div>

        <div
          className='d-flex flex-stack justify-content-end flex-row-fluid'
          id='kt_app_navbar_wrapper'
        >
          <div className='page-entry d-flex flex-column flex-row-fluid'>
            <SappBreadCrumbs breadcrumbs={breadcrumbs} />

            <div className='page-title d-flex align-items-center'>
              <h1 className='page-heading d-flex text-gray-800 fw-bold fs-2x flex-column justify-content-center my-0'>
                {pageTitle}
              </h1>
            </div>
          </div>

          <div className='app-navbar flex-shrink-0'>
            <div className='d-flex align-items-center align-items-stretch'>
              <div
                id='kt_header_search'
                className='header-search d-flex align-items-center w-100 w-lg-250px'
                data-kt-search-keypress='true'
                data-kt-search-min-length='2'
                data-kt-search-enter='enter'
                data-kt-search-layout='menu'
                data-kt-search-responsive='lg'
                data-kt-menu-trigger='auto'
                data-kt-menu-permanent='true'
                data-kt-menu-placement='bottom-end'
              >
                <div
                  data-kt-search-element='toggle'
                  className='search-toggle-mobile d-flex d-lg-none align-items-center'
                >
                  <div className='d-flex btn btn-icon btn-icon-gray-500 btn-active-light btn-active-color-primary w-35px h-35px w-lg-40px h-lg-40px'>
                    <i className='ki-outline ki-magnifier fs-1'></i>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='app-navbar-item ms-2 ms-lg-3 '>
              <Popover arrow={false} placement='bottomRight' content={<QuickLink />}>
                <div className='btn btn-icon btn-icon-gray-500 btn-active-light btn-active-color-primary w-35px h-35px w-lg-40px h-lg-40px custom-background-icon'>
                  <i className='ki-outline ki-add-notepad fs-1'></i>
                </div>
              </Popover>
            </div>
            <div className='app-navbar-item'>
              <Popover placement='bottomRight' content={<MyApp />} arrow={false}>
                <div className='app-navbar-item ms-2 ms-lg-3'>
                  <div className='btn btn-icon btn-icon-gray-500 btn-active-light btn-active-color-primary w-35px h-35px w-lg-40px h-lg-40px custom-background-icon'>
                    <i className='ki-outline ki-element-11 fs-1'></i>
                  </div>
                </div>
              </Popover>
            </div> */}
            {/* start:: button notification*/}
            <div className='app-navbar-item ms-2 ms-lg-3 d-none'>
              <div
                className='btn btn-icon btn-icon-gray-500 btn-active-light btn-active-color-primary w-35px h-35px w-lg-40px h-lg-40px position-relative custom-background-icon'
                id='kt_drawer_chat_toggle'
                onClick={() => setOpenNotifications(true)}
              >
                <i className='ki-outline ki-notification-on   fs-1'></i>
                {/* <span className='position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger w-15px h-15px ms-n4 mt-3'>
                  5
                </span> */}
              </div>
            </div>
            {/* end:: button notification*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
