import {zodResolver} from '@hookform/resolvers/zod'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {PageLink, VALIDATION_FILED} from 'src/constants'
import {useUserContext} from 'src/context/UserProvider'
import {z} from 'zod'
import sapp from 'src/_metronic/assets/media/logos/sapp-logo-default.svg'

interface IInputProps {
  email: string
}

const FogotPassword = () => {
  const navigate = useNavigate()
  const {forgotPasswordOTP} = useUserContext()
  // Validate for input
  const validationSchema = z.object({
    email: z.string({required_error: VALIDATION_FILED}).email(),
  })

  // Using validate for input
  const {control, handleSubmit} = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const onSubmit = (data: IInputProps) => {
    forgotPasswordOTP(data.email).then(() => {
      navigate(PageLink.ENTER_OTP)
    })
  }

  return (
    <div className='d-flex flex-column flex-root sapp-height-layout--login justify-content-center'>
      <div className='d-flex justify-content-center'>
        <img className='sapp-logo w-125px object-fit-contain' src={sapp} alt='sapp' />
      </div>
      <div className='d-flex flex-column  flex-lg-row justify-content-center'>
        <div className='d-flex  flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20'>
          <div className='bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20 box-shadow--custom'>
            <div className='d-flex flex-center flex-column'>
              {/* Start Form Login */}
              <form className='form w-100' onSubmit={handleSubmit(onSubmit)}>
                <div className='text-center mb-11'>
                  <h1 className='text-dark fw-bolder mb-3 text-left'>Forgot Password</h1>
                  <div className='text-gray-500 fw-semibold fs-6 text-left'>
                    Enter the email you used to create your account so we can send you 6 digits code
                    to reset your password
                  </div>
                </div>

                {/* Start TextField Email */}
                <div className='fv-row mb-8'>
                  <HookFormTextField
                    control={control}
                    name='email'
                    placeholder='Email'
                    className='sapp-h-50px'
                  />
                </div>
                {/* End TextField Email */}

                {/* Start Button Login */}
                <div className='d-grid mb-5 sapp-h-50px'>
                  <ButtonPrimary title='Send' type='submit' className='sapp-h-50px' />
                </div>
                {/* End Button Login */}
              </form>

              <div className='w-100 sapp-h-50px'>
                <ButtonSecondary
                  onClick={() => navigate(-1)}
                  title='Back to Sign In'
                  className='w-100 sapp-h-50px'
                />
              </div>
              {/* End Form Login */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FogotPassword
