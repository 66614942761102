import CreatedContent from '../../created-content/CreatedContent'
import {convertTime} from 'src/utils/string'
import {htmlToRaw} from 'src/utils'
import {z} from 'zod'
import {VALIDATION_FILED} from 'src/constants'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useState} from 'react'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {debounce} from 'lodash'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import {useConfirm} from 'src/hooks/use-confirm'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'

interface IProps {
  setOpenQuestionSelect: any
  listTimeLine: any[]
  tab: number
  actionDelete: any
  currentTime: any
  setListTimeLine?: any
  actionEdit?: any
}
const TabVideoQuestion = ({
  setOpenQuestionSelect,
  listTimeLine,
  tab,
  actionDelete,
  currentTime,
  setListTimeLine,
  actionEdit,
}: IProps) => {
  const validationSchema = z.object({
    name: z.string({required_error: VALIDATION_FILED}).trim().min(1, VALIDATION_FILED),
  })
  const useFormProp = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const {control, setValue, reset, handleSubmit} = useFormProp
  const [creatingNewMenu, setCreatingNewMenu] = useState(false)
  const [editMenu, setEditMenu] = useState<any>()
  // add new item by tab
  const actionAddNew = (e: any) => {
    if (tab === 1) {
      setOpenQuestionSelect({state: true})
    } else {
      setCreatingNewMenu(true)
    }
    e.target.blur()
  }
  //cancel add new menu name
  const handleCancel = () => {
    setCreatingNewMenu(false)
    reset()
  }
  // handle add new menu item
  const handleAddNewMenu = (e: string) => {
    setListTimeLine((prev: any) => {
      const oldList = [...prev]
      oldList.push({
        menuName: e,
        time: Math.floor(currentTime),
      })
      handleCancel()
      return oldList
    })
    reset()
  }
  //handle click edit icon seprate by tab active
  const handleEdit = (i: number) => {
    if (tab === 1) {
      actionEdit(i)
    } else {
      setEditMenu(i)
      setValue('name', listTimeLine[i].menuName)
    }
  }
  //handle edit menu time line
  const handleEditMenuTimeLine = (index: number, name: string) => {
    const oldListMenuTimeLine = [...listTimeLine]
    oldListMenuTimeLine[index].menuName = name
    setListTimeLine(oldListMenuTimeLine)
    setEditMenu(undefined)
    reset()
  }
  //debounce edit and create
  const debouncedHandleCreate = debounce(handleAddNewMenu, 1000)
  const debouncHandleEdit = debounce(handleEditMenuTimeLine, 1000)

  const {confirm, contextHolder} = useConfirm()
  const handleDelete = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: () => {
        handleCancel()
      },
    })
  }

  return (
    <div className='w-100 p-8 h-100'>
      {contextHolder}
      <div className='d-flex align-items-center justify-content-between mb-8'>
        <div className='sapp-questionTab-title'>
          {`${tab === 1 ? 'Questions' : 'Timeline'} (${listTimeLine?.length})`}{' '}
        </div>
        <ButtonPrimary
          title={`Add ${tab === 1 ? 'Question' : 'Timeline'}`}
          onClick={(e: any) => actionAddNew(e)}
        />
      </div>
      <div className='sapp-list-question-added-container'>
        <div className='w-100'>
          {creatingNewMenu && tab === 0 && (
            <CreatedContent iconGrayColor={'600'} classNameContainer='p-4 mb-5 text-gray-600'>
              <div className='d-flex flex-column flex-grow-1'>
                {/* <form > */}
                <HookFormTextField
                  className='sapp-input-menuVideo sapp-border-none sapp-border-input'
                  placeholder='Describe this sequence here...'
                  name='name'
                  control={control}
                  onChange={(e) => {
                    debouncedHandleCreate(e.target.value)
                  }}
                  onSubmit={handleSubmit((e) => handleAddNewMenu(e.name))}
                />
                <div className='sapp-text-time mt-2'>{convertTime(currentTime)}</div>
                {/* </form> */}
              </div>
            </CreatedContent>
          )}
          {listTimeLine?.length > 0 &&
            listTimeLine?.map((e, i) => {
              if (editMenu === i && tab === 0) {
                return (
                  <CreatedContent
                    actionDelete={() => {
                      actionDelete(i)
                      setEditMenu(undefined)
                      reset()
                    }}
                    iconGrayColor={'600'}
                    classNameContainer='p-4 mb-5 text-gray-600'
                  >
                    <div className='d-flex flex-column flex-grow-1'>
                      {/* <form > */}
                      <HookFormTextField
                        className='sapp-input-menuVideo sapp-border-none sapp-border-input'
                        placeholder='Describe this sequence here...'
                        name='name'
                        control={control}
                        onChange={(e) => {
                          debouncHandleEdit(i, e.target.value)
                        }}
                        onSubmit={handleSubmit((e) => handleEditMenuTimeLine(i, e.name))}
                      />
                      <div className='sapp-text-time mt-2'>{convertTime(e.time)}</div>
                      {/* </form> */}
                    </div>
                  </CreatedContent>
                )
              }
              return (
                <CreatedContent
                  iconGrayColor={'600'}
                  classNameContainer='sapp-p-24px sapp-mb-32px text-gray-600'
                >
                  <div className='w-100'>
                    <div className='sapp-text-truncate-1 text-wrap sapp-question-name border-light border-bottom pb-5'>
                      {htmlToRaw(e.menuName)}
                    </div>
                    <div className='sapp-text-time mt-3 d-flex justify-content-between align-items-center'>
                      {convertTime(e.time)}
                      <div className='d-flex'>
                        {actionEdit && (
                          <ButtonIconOnly
                            iconName='notepad-edit'
                            onClick={() => handleEdit(i)}
                            iconType='outline'
                            bg={'sapp-custom-bg-transparent'}
                            iconGrayColor={'600'}
                          />
                        )}
                        <div className='ms-1'>
                          {actionDelete && (
                            <ButtonIconOnly
                              iconName='trash'
                              onClick={() => actionDelete(i)}
                              iconType='outline'
                              bg={'sapp-custom-bg-transparent'}
                              activeColor='danger'
                              iconGrayColor={'600'}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CreatedContent>
              )
            })}
        </div>
      </div>
    </div>
  )
}
export default TabVideoQuestion
