import { Col, Row } from 'antd'
import React, { ReactNode } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import ButtonDropdown from 'src/components/base/button/ButtonDropdown'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import { styled } from 'styled-components'

interface IStep {
  step: {
    title: string
    status: string
  }[]
  setNewStep: any
  isNext?: boolean
  onCancel?: () => void
  onClick?: () => void
  okButtonCaption?: string | undefined
  children?: ReactNode
  showButtonPrimary?: boolean | undefined
  showCancel?: boolean | undefined
  cancelButtonCaption?: string | undefined
  className?: string
  loading?: boolean
}

const Processbar = ({
  step,
  setNewStep,
  isNext = true,
  onCancel,
  onClick,
  okButtonCaption = 'Save',
  children,
  showButtonPrimary = true,
  showCancel = false,
  cancelButtonCaption,
  className = '',
  loading,
}: IStep) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const ProcessStyled = styled.div<{ $title?: any }>`
    display: flex;
    width: 100%;
    align-items: center;
    .sapp-steps {
      position: relative;
      width: 40px;
      height: 40px;
      border: 1px var(--bs-primary-border) solid;
      border-radius: 50px;
      background: #ffffff;
      color: var(--bs-primary-border);
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
        border: none;
        color: #fff;
        background: #ffb800;
      }
      &.current {
        cursor: initial;
        border: none;
        background: #ffb800;
        border: 1px #faaf00 solid;
        color: white;
      }
      &.current::before {
        color: #faaf00;
        font-weight: 600;
      }
    }
    .sapp-steps-line {
      height: 1px;
      background: var(--bs-primary-border);
      flex: 1;
      margin: 0 32px 0 32px;
      position: relative;
      &:last-child {
        display: none;
      }
      &.active::before {
        width: 100%;
        height: 100%;
        background: var(--bs-primary);
        content: '';
        position: absolute;
        left: 0;
      }
    }
  `

  const handleGotoStep = (index: number) => {
    const _new = step.map((e, idx) => {
      if (index > idx) {
        return { ...e, status: 'active' }
      }
      if (index === idx) {
        return { ...e, status: 'current' }
      }
      if (index < idx) {
        return { ...e, status: '' }
      }
    })
    if (id !== 'undefined') {
      isNext && setNewStep(_new)
    }
  }
  return (
    <div className='sapp-proress-bar-wrapper bg-white processbar'>
      <Row className='justify-content-between'>
        <Col span={3}>
          {showCancel ? (
            <ButtonSecondary title='Cancel' isListScreen onClick={onCancel} loading={loading} />
          ) : (
            <ButtonIcon
              title={cancelButtonCaption}
              className='btn btn-sapp-icon'
              onClick={onCancel}
              isListScreen
              loading={loading}
            >
              <KTIcon iconName='black-left' className='fs-3 pe-1' iconType='outline' />
            </ButtonIcon>
          )}
        </Col>
        <Col
          span={14}
          className={`d-flex justify-content-center ${step?.length <= 2 ? 'sapp-mw-520px' : ''}`}
        >
          <ProcessStyled $title={step} className={className}>
            {step?.map((e: any, i: number) => {
              return (
                <React.Fragment key={i}>
                  <div
                    className={`sapp-steps ${
                      (e.status === 'active' || e.status === 'current') && 'active '
                    }${e.status === 'current' && 'current'}`}
                    onClick={() => {
                      e.link ? navigate(e.link) : handleGotoStep(i)
                    }}
                  >
                    {i + 1}
                  </div>
                  <div
                    className={`sapp-title-step ${
                      (e.status === 'active' || e.status === 'current') && 'active'
                    }`}
                  >
                    {e?.title}
                  </div>
                  <div
                    className={`sapp-steps-line ${
                      e.status === 'active' && i < step.length && 'active'
                    }`}
                  ></div>
                </React.Fragment>
              )
            })}
          </ProcessStyled>
        </Col>
        <Col span={3} className='d-flex justify-content-end'>
          {showButtonPrimary ? (
            <ButtonPrimary
              title={okButtonCaption}
              isListScreen
              onClick={onClick}
              loading={loading}
            />
          ) : (
            <ButtonDropdown
              title={okButtonCaption}
              onClick={onClick}
              isListScreen
              loading={loading}
            >
              {children}
            </ButtonDropdown>
          )}
        </Col>
      </Row>
    </div>
  )
}
export default Processbar
